import * as types from 'src/constants/store/musicSocial';
const _ = require('lodash');
const listMusicSpace: any = localStorage.getItem('listMusic');

const initialState = {
  listMusic: JSON.parse(listMusicSpace) || [],
  listCategoriesReport: [],
  paused: false,
  playAudio: false,
  audioIndex: 0,
  detailAlbum: null,
  topMusic: [],
  musicChart: [],
  openMiniMusicPlayer: false,
  volumeMusic: 0.3,
  checkPathOutMusic: false,
  dataDetailPlaylist: [],
  isShowMiniAudio: false,
  activeMusic: null,
  randomMusic: false,
  mute: false,
  isReplay: false
};

export const musicReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_REPORT_MUSIC: {
      let { data } = action.payload;
      return {
        ...state,
        listCategoriesReport: data.data
      };
    }
    case types.GET_LIST_MUSIC: {
      let { data } = action.payload;

      const isSingleMusic = !Array.isArray(data.data); // true nếu là bài hát đơn lẻ
      const newMusic = isSingleMusic ? data.data : null;
      let newState = _.cloneDeep(state.listMusic);

      if (newMusic) {
        // Trường hợp thêm bài hát mới vào đầu mảng
        newState = newState.filter(item => item.id !== newMusic.id); // Loại bỏ trùng lặp
        newState.unshift(newMusic);
      } else {
        // Trường hợp cập nhật toàn bộ danh sách
        newState = _.cloneDeep(data.data);
      }

      return {
        ...state,
        listMusic: newState,
        audioIndex: data.index,
        playAudio: data.playAudio
      };
    }
    case types.ACTION_MUSIC: {
      let { audioIndex, playAudio, paused } = action.payload;
      return {
        ...state,
        paused: paused,
        playAudio: playAudio,
        audioIndex: audioIndex
      };
    }
    case types.DETAIL_ALBUM: {
      return {
        ...state,
        detailAlbum: action.payload
      };
    }
    case types.UPDATE_ALBUM_MUSICSPACE: {
      const { data } = action.payload;
      return {
        ...state,
        detailAlbum: data
      };
    }

    case types.RESET_ALBUM_MUSICSPACE: {
      return {
        ...state,
        detailAlbum: null
      };
    }

    case types.GET_TOP_MUSIC: {
      return {
        ...state,
        topMusic: action.payload
      };
    }
    case types.GET_MUSIC_CHART: {
      return {
        ...state,
        musicChart: action.payload
      };
    }
    case types.SHOW_MUSIC_PLAYER: {
      return {
        ...state,
        openMiniMusicPlayer: action.payload
      };
    }
    case types.VOLUME_MUSIC: {
      const { mute, volume } = action.payload;
      return {
        ...state,
        volumeMusic: volume,
        mute
      };
    }
    case types.CHECK_PATH_MUSIC: {
      return {
        ...state,
        checkPathOutMusic: action.payload
      };
    }
    case types.GET_DATA_PLAYLIST: {
      return {
        ...state,
        dataDetailPlaylist: action.payload
      };
    }
    case types.SHOW_MINI_AUDIO: {
      return {
        ...state,
        isShowMiniAudio: action.payload
      };
    }
    case types.ACTIVE_MUSIC: {
      const item = action.payload;
      return {
        ...state,
        activeMusic: item,
        isReplay: item.is_replay
      };
    }
    case types.RANDOM_MUSIC: {
      const type = action.payload;
      return {
        ...state,
        randomMusic: type
      };
    }

    case types.RESET_MUSIC_DATA: {
      return {
        ...state,
        topMusic: []
      };
    }

    default:
      return state;
  }
};
