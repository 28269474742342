/* eslint-disable react-hooks/exhaustive-deps */ import {
  IconButton,
  Theme
} from '@material-ui/core';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { OnChangeHandlerFunc } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import {
  postDonateAccountUser,
  postDonatePage
} from 'src/apis/socialPayment.api';
import { searchUserGlobal } from 'src/apis/socialSearch.api';
import DialogPayment from 'src/components/Dialog/DialogPayment';
import DialogPaymentDetail from 'src/components/Dialog/DialogPaymentDetail';
import Emoji from 'src/components/Emoji/EmojiPopup';
import Gif from 'src/components/Emoji/GifPopup';
import Sticky from 'src/components/Emoji/StickyPopup';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { discountData } from 'src/constants/common';
import { getColorImageReq } from 'src/store/action/settingAction';
import CustomizedDialogs from '../Dialog/DialogConfirmDelete';
import Mentions from '../Mentions/Mentions';
import imageDefault from 'src/assets/images/pages_profile.png';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';

const { forwardRef } = React;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      form: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 36
      },
      root: {
        display: 'flex !important',
        margin: '5px 5px 5px 0px',
        alignItems: 'center',
        width: '100%'
      },

      right_input: {
        borderRadius: '20px',
        margin: ' 0px 5px 0px 5px',
        width: '93%'
      },
      text_input: {
        display: 'block',
        height: '100%',
        marginLeft: '8px',
        width: '100%',
        fontSize: '14px',
        padding: '8px 5px',
        color: theme.palette.text.primary
      },
      group_button: {
        display: 'flex',
        alignItems: 'center'
      },
      action_button: {
        width: 32,
        height: 32
      },
      img: {
        display: 'block',
        width: 'auto',
        height: '100%'
      },
      imgSticky: {
        display: 'block',
        height: 80,
        width: 80
      },
      thumbInner: {
        display: 'block',
        minWidth: 0,
        overflow: 'hidden',
        width: '100%'
      },
      thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        marginBottom: 8,
        marginRight: 8,
        width: '100%',
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
      },
      thumbsContainer: {
        paddingLeft: 40,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
      },
      icon: {
        width: 32,
        height: 32,
        display: 'flex'
      },
      avatar: {
        width: 32,
        height: 32
      },
      loadingMedia: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0px',
        marginBottom: '10px'
      },
      linearPro: {
        height: '6px',
        borderRadius: '10px'
      },
      wrapItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px'
      },
      wrapText: {
        display: 'flex',
        flexDirection: 'column'
      },
      title: {
        fontSize: '15px !important',
        fontWeight: '500 !important'
      },
      subTitle: {
        fontSize: '13px !important',
        color: '#65676b',
        display: 'flex',
        alignItems: 'center'
      },
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        wordBreak: 'break-all'
      }
    }),
  { index: 1 }
);

interface Props {
  style?: React.CSSProperties | any;
  accept?: any;
  onSubmitForm?: any;
  defaultValue?: any;
  gifSelected?: any;
  setGifSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  stickySelected?: any;
  setStickySelected?: React.Dispatch<React.SetStateAction<any>> | any;
  type?: String;
  chooseFocus?: any;
  setChooseFocus?: React.Dispatch<React.SetStateAction<any>> | any;
  post?: any;
  replyActionComment?: any;
  setReplyActionComment?: React.Dispatch<React.SetStateAction<any>> | any;
  sendTypingEvent?: any;
  typeComment?: String;
}

const CommentBox = forwardRef<any, any>((props: Props, ref: any) => {
  let {
    style,
    accept,
    onSubmitForm,
    defaultValue,
    gifSelected,
    setGifSelected,
    stickySelected,
    setStickySelected,
    type,
    chooseFocus,
    setChooseFocus,
    post,
    replyActionComment,
    setReplyActionComment,
    sendTypingEvent,
    typeComment = 'default'
  } = props;
  const classes = useStyles();
  const inputRef: any = React.useRef();
  const theme = useTheme();
  const [progress, setProgress] = React.useState<any>(0);
  const [localContentComment, setLocalContentComment] = React.useState(
    defaultValue?.text ?? replyActionComment ?? ''
  );
  const [files, setFiles] = useState({ data: [], status: 'select' });
  const [openDialogValidate, setOpenDialogValidate] =
    React.useState<any>(false);
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [openDialogPaymentDetail, setOpenDialogPaymentDetail] =
    useState<any>(false);
  const roleInteract: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorElGif, setAnchorElGif] =
    React.useState<HTMLButtonElement | null>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openPopperSticky, setOpenPopperSticky] = React.useState(false);
  const [anchorElSticky, setAnchorElSticky] =
    React.useState<HTMLButtonElement | null>(null);
  const [loadingDonate, setLoadingDonate] = React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const dispatch = useDispatch();
  const [anchorElPayment, setAnchorElPayment] =
    React.useState<HTMLButtonElement | null>(null);
  const openDonate = Boolean(anchorElPayment);
  const [exchangeSelected, setExchangeSelected] = React.useState<any>(
    discountData[0]
  );
  const [typeFocus, setTypeFocus] = React.useState<any>('');

  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  // state mention
  const [listMentions, setListMentions] = React.useState<any>(
    friends.slice(0, 8)
  );
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [positionCursorContentComment, setPositionCursorContentComment] =
    React.useState<number>(defaultValue?.text?.length ?? 0);
  const [objectMentions, setObjectMentions] = React.useState<any>(
    defaultValue?.status_tags ?? []
  );
  const inputElement: any = document.getElementById(
    `textInputCommentBox${post.id}`
  );

  const handleOpenPayment = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPayment(event.currentTarget);
  };

  const handlePaymentDonate = async (data: any) => {
    try {
      let res;
      if (post?.page_owner) {
        res = await postDonatePage(data);
      } else {
        res = await postDonateAccountUser(data);
      }
      if (res.status === 200) {
        setLoadingDonate(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Donate thành công.'
        });
      }
    } catch (err) {
      setLoadingDonate(false);
      setOpenSnackbar(true);
      setNoti({
        code: 200,
        message: 'Donate thất bại!'
      });
    }
  };
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box className={classes.loadingMedia}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            className={classes.linearPro}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  // React.useEffect(() => {
  //   if (files?.data?.length) {
  //     dispatch(
  //       getColorImageReq(files?.data, setProgress, window.location.href)
  //     );
  //   }
  // }, [files?.data]);

  const formik = useFormik({
    initialValues: {
      files: [],
      textInputCommentBox: '',
      display_gift: '',
      display_sticky: ''
    },
    onSubmit: values => {
      onSubmitForm({
        ...values,
        status_tags: objectMentions
      });
      setReplyActionComment && setReplyActionComment('');
      formik.resetForm();
      setFiles({ data: [], status: 'select' });
      setGifSelected(null);
      setStickySelected && setStickySelected(null);
      setLocalContentComment('');
      setObjectMentions([]);
    }
  });

  useEffect(() => {
    let text = defaultValue?.text ?? '';
    formik.setFieldValue('textInputCommentBox', text);
    if (defaultValue?.files?.length > 0) {
      setGifSelected(null);
      setStickySelected && setStickySelected(null);
      let files: any = [defaultValue.files[0]];
      setFiles({ data: files, status: 'edit' });
      formik.setFieldValue('files', files);
    }
    if (defaultValue?.tags && defaultValue.tags.length) {
      setObjectMentions(defaultValue.tags);
    }

    if (
      defaultValue?.extra_body &&
      Object.keys(defaultValue?.extra_body).length &&
      !defaultValue?.files?.length
    ) {
      if (defaultValue?.extra_body?.description === 'sticky') {
        setStickySelected && setStickySelected(defaultValue.extra_body);
        formik.setFieldValue(
          'display_sticky',
          defaultValue.extra_body?.link ?? defaultValue.extra_body?.url
        );
      } else {
        setGifSelected(
          defaultValue.extra_body?.link ?? defaultValue.extra_body?.url
        );
        formik.setFieldValue(
          'display_gift',
          defaultValue.extra_body?.link ?? defaultValue.extra_body?.url
        );
      }
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: accept ?? '',
    maxFiles: 1,
    noClick: true,
    onDrop: (acceptedFiles: any) => {
      const maxSize = 25 * 1024 * 1024; // max size 25MB
      const file = acceptedFiles[0];
      if (file.size > maxSize) {
        setOpenSnackbar(true);
        setNoti({
          code: 400,
          message: 'Dung lượng file tối đa 25MB'
        });
        return;
      }

      setGifSelected(null);
      setStickySelected && setStickySelected(null);
      let files: any = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles({ data: files, status: 'select' });
      formik.setFieldValue('files', acceptedFiles);
      ref?.current?.focus();
      inputRef?.current?.focus();
    },
    multiple: false
  });

  const removeFile = (file: any) => () => {
    const newFiles: any = { ...files };
    newFiles.data.splice(newFiles.data.indexOf(file), 1);
    setFiles(newFiles);
    formik.setFieldValue('files', []);
  };
  let thumbs;

  if (
    (gifSelected &&
      formik?.values?.display_gift &&
      !files.data.length &&
      !stickySelected) ||
    (type === 'edit' && gifSelected && !stickySelected)
  ) {
    thumbs = (
      <Box
        className={classes.thumb}
        sx={{
          border:
            theme.palette.mode === 'dark'
              ? '1px solid #444444'
              : '1px solid #eaeaea'
        }}
      >
        <div className={classes.thumbInner}>
          <img
            src={type === 'edit' ? gifSelected : formik?.values?.display_gift}
            className={classes.img}
            alt="gif"
          />
        </div>

        <IconButton
          onClick={() => {
            formik.setFieldValue('display_gift', '');
            setGifSelected(null);
          }}
          className={classes.icon}
        >
          <i
            className="far fa-times"
            style={{
              color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
            }}
          ></i>
        </IconButton>
      </Box>
    );
  } else if (
    (stickySelected &&
      formik?.values?.display_sticky &&
      !files.data.length &&
      !gifSelected) ||
    (type === 'edit' && !gifSelected && stickySelected)
  ) {
    thumbs = (
      <Box
        className={classes.thumb}
        sx={{
          border:
            theme.palette.mode === 'dark'
              ? '1px solid #444444'
              : '1px solid #eaeaea'
        }}
      >
        <div className={classes.thumbInner}>
          <img
            src={
              type === 'edit'
                ? stickySelected?.url ?? stickySelected?.link
                : formik?.values?.display_sticky
            }
            className={classes.imgSticky}
            alt="sticky"
          />
        </div>

        <IconButton
          onClick={() => {
            formik.setFieldValue('display_sticky', '');
            setStickySelected(null);
          }}
          className={classes.icon}
        >
          <i
            className="far fa-times"
            style={{
              color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
            }}
          ></i>
        </IconButton>
      </Box>
    );
  } else if (files.status === 'select') {
    thumbs = files.data.map((file: any, index) => {
      if (file?.type.match('image')) {
        return (
          <Box
            className={classes.thumb}
            key={file.name}
            sx={{
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #444444'
                  : '1px solid #eaeaea'
            }}
          >
            <div className={classes.thumbInner}>
              <img src={file.preview} className={classes.img} alt="" />
            </div>

            <IconButton onClick={removeFile(file)} className={classes.icon}>
              <i
                className="far fa-times"
                style={{
                  color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
                }}
              ></i>
            </IconButton>
          </Box>
        );
      } else {
        return (
          <Box
            className={classes.thumb}
            key={file?.name}
            sx={{
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #444444'
                  : '1px solid #eaeaea'
            }}
          >
            <div className={classes.thumbInner}>
              {progress !== 0 && progress !== 100 && (
                <LinearProgressWithLabel value={progress} />
              )}
              <video src={file?.preview} className={classes.img} />
            </div>
            <IconButton onClick={removeFile(file)} className={classes.icon}>
              <i
                className="far fa-times"
                style={{
                  color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
                }}
              ></i>
            </IconButton>
          </Box>
        );
      }
    });
  } else {
    thumbs = files.data.map((file: any, index) => {
      let url = file?.url ?? '';
      if (file?.type?.match('image')) {
        return (
          <Box
            className={classes.thumb}
            key={file?.name}
            sx={{
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #444444'
                  : '1px solid #eaeaea'
            }}
          >
            <div className={classes.thumbInner}>
              <img src={url} className={classes.img} alt="img" />
            </div>

            <IconButton onClick={removeFile(file)} className={classes.icon}>
              <i
                className="far fa-times"
                style={{
                  color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
                }}
              ></i>
            </IconButton>
          </Box>
        );
      } else {
        return (
          <Box
            className={classes.thumb}
            key={file?.name}
            sx={{
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #444444'
                  : '1px solid #eaeaea'
            }}
          >
            <div className={classes.thumbInner}>
              <video src={url} className={classes.img} />
            </div>
            <IconButton onClick={removeFile(file)} className={classes.icon}>
              <i
                className="far fa-times"
                style={{
                  color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
                }}
              ></i>
            </IconButton>
          </Box>
        );
      }
    });
  }

  const handleClickGif = (event: React.MouseEvent<HTMLButtonElement>, type) => {
    setTypeFocus('');
    if (type === 'gif') {
      setAnchorElGif(event.currentTarget);
    } else {
      setAnchorElSticky(event.currentTarget);
    }
  };

  const handleCloseEmoji = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      (defaultValue?.text || defaultValue?.name) &&
      localContentComment.length === 0
    ) {
      let textDefault = defaultValue?.text ?? defaultValue?.name;
      setLocalContentComment(textDefault + ' ');
      setObjectMentions(defaultValue?.status_tags);
      inputRef.current.focus();
    }
  }, [JSON.stringify(defaultValue), timestamp]);

  const handleIconChange = e => {
    setLocalContentComment(prev => prev + `${e}`);
  };

  const handleEmojiClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const listIcons = [
    {
      id: 10,
      icon: 'fal fa-star',
      action: e => handleOpenPayment(e),
      show:
        ((post?.account?.earn_money &&
          (!post?.page_owner || post?.page?.earn_money)) ||
          post?.page_owner?.earn_money ||
          post?.page?.earn_money) &&
        post?.account?.id !== meInfo?.id
          ? 'show'
          : 'hide'
    },
    {
      id: 1,
      icon: 'fa-light fa-face-smile',
      action: e => handleEmojiClick(e),
      show: 'show'
    },
    {
      id: 2,
      icon: 'fa-light fa-camera',
      action: e => open(),
      show:
        files?.data.length > 0 ||
        gifSelected ||
        (stickySelected && Object.keys(stickySelected).length) ||
        typeComment === 'liveChat'
          ? 'hide'
          : 'show'
    },
    {
      id: 3,
      icon: 'fa-light fa-gif',
      action: e => {
        setOpenPopper(true);
        handleClickGif(e, 'gif');
        inputRef?.current?.focus();
      },
      show:
        files?.data.length > 0 ||
        gifSelected ||
        (stickySelected && Object.keys(stickySelected).length) ||
        typeComment === 'liveChat'
          ? 'hide'
          : 'show'
    },
    {
      id: 4,
      icon: 'fa-light fa-message-smile',
      action: e => {
        setOpenPopperSticky(true);
        handleClickGif(e, 'sticky');
        inputRef?.current?.focus();
      },
      show:
        files?.data.length > 0 ||
        gifSelected ||
        (stickySelected && Object.keys(stickySelected).length)
          ? 'hide'
          : 'show'
    }
  ];

  const handlePaste = e => {
    if (Array.from(e.clipboardData.files).length > 0) {
      setGifSelected(null);
      setStickySelected(null);
      let file_: any = Array.from(e.clipboardData.files).map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles({ data: [file_[0]] as any, status: 'select' });
      formik.setFieldValue('files', [Array.from(e.clipboardData.files)[0]]);
      inputRef?.current?.focus();
    }

    if (e.clipboardData.getData('Text').length) {
      setPositionCursorContentComment(
        positionCursorContentComment + e.clipboardData.getData('Text').length
      );
    }
  };

  const handleChange: OnChangeHandlerFunc = (event, newValue, displayValue) => {
    setLocalContentComment(newValue);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13 && !event.altKey) {
      event.preventDefault();
      if (
        gifSelected ||
        stickySelected ||
        files.data.length > 0 ||
        localContentComment.length > 0
      ) {
        formik.handleSubmit();
      }
    } else if (event.altKey && event.keyCode === 13) {
      // Thêm một dòng mới vào nội dung
      setLocalContentComment(localContentComment + '\n');
      event.preventDefault(); // Ngăn việc xuống dòng mặc định của textarea
    } else if (event.key === '@') {
      setListMentions(friends.slice(0, 8));
    }
  };

  const handleActionMention = id => {
    const mentionEndPosition = inputElement.selectionEnd;

    let item = listMentions?.find(el => el.id === id);
    if (!item) {
      item = friends.find(el => el.id === id);
    }
    let typeObjectMention = item?.page_relationship
      ? 'Page'
      : item?.group_relationship
      ? 'Group'
      : 'Account';
    let nameObjectMention = item?.display_name ?? item?.title ?? item?.display;

    setObjectMentions(prev => [
      ...prev,
      {
        id: item?.id ?? '',
        entity_id: item?.id ?? '',
        type: typeObjectMention,
        name: nameObjectMention,
        position: mentionEndPosition
      }
    ]);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  React.useEffect(() => {
    formik.setFieldValue('textInputCommentBox', localContentComment);
    setObjectMentions(
      objectMentions.filter(el => el.position < localContentComment.length)
    );
  }, [JSON.stringify(localContentComment)]);

  React.useEffect(() => {
    if (inputElement) {
      inputElement.style.color = theme.palette.text.primary;
      inputElement.style.top = '2px';
    }
  }, [inputElement]);

  const debounceSearchGlobal = React.useCallback(
    _.debounce(async function (q, callback) {
      if (!q) {
        callback(
          friends.map((item: any) => ({
            id: item.id,
            display: item?.display_name ?? item?.title,
            avatar_media: item?.avatar_media ?? item?.banner,
            relationships: item
          }))
        );
      }
      let response = await searchUserGlobal({
        q,
        limit: 5,
        type: ['accounts', 'pages', 'groups']
      });

      if (response.status === 200) {
        let accounts: any = response.data.accounts.slice(0, 3);
        let pages: any = response.data.pages.slice(0, 3);
        let groups: any = response.data.groups.slice(0, 3);
        let optionNewSearch: any = [...accounts, ...groups, ...pages];
        if (optionNewSearch.length) {
          const results = optionNewSearch.map((item: any, index: any) => ({
            id: item.id,
            display: item?.display_name ?? item?.title,
            avatar_media: item?.avatar_media ?? item?.banner,
            relationships: item
          }));
          setListMentions(optionNewSearch);
          callback(results);
        }
      }
    }, 800),
    []
  );

  React.useEffect(() => {
    if (chooseFocus && inputRef.current) {
      inputRef.current.focus();
      setChooseFocus && setChooseFocus(false);
    }
  }, [chooseFocus, inputRef]);

  return (
    <>
      <div style={style} className={classes.root}>
        <AvatarSocial
          type="feed"
          avatarObj={
            roleInteract?.page_owner_id
              ? roleInteract?.avatar_media ?? {
                  show_url: imageDefault
                }
              : meInfo?.avatar_media
          }
          style={{ width: 32, height: 32 }}
        />
        <Box
          className={classes.right_input}
          sx={{ backgroundColor: 'background.input' }}
        >
          <form
            onSubmit={formik.handleSubmit}
            className={classes.form}
            noValidate
            autoComplete="off"
          >
            <Mentions
              disabled={progress > 0 && progress < 100}
              inputRef={inputRef}
              idInput={`textInputCommentBox${post.id}`}
              name="textInputCommentBox"
              style={{
                display: 'block',
                height: '100%',
                marginLeft: '8px',
                width: '100%',
                fontSize: '14px',
                color: theme.palette.text.primary
              }}
              localContent={localContentComment}
              sendTypingEvent={sendTypingEvent}
              handleChange={handleChange}
              handlePaste={handlePaste}
              debounceSearchGlobal={debounceSearchGlobal}
              handleActionMention={handleActionMention}
              handleKeyDown={handleKeyDown}
              placeholder="Viết bình luận..."
            />

            <div
              {...getRootProps({ className: 'dropzone' })}
              className={classes.group_button}
            >
              <input {...getInputProps()} id="files" name="files" />
              {listIcons?.map(
                (el: any, index) =>
                  el.show === 'show' && (
                    <IconButton
                      key={index}
                      size="small"
                      className={classes.action_button}
                      onClick={e => el.action(e)}
                    >
                      <i
                        className={el.icon}
                        style={{
                          color:
                            theme.palette.mode === 'dark'
                              ? '#d2d2d2'
                              : '#050505'
                        }}
                      ></i>
                    </IconButton>
                  )
              )}
            </div>
          </form>
        </Box>

        {openDonate && (
          <DialogPayment
            anchorEl={anchorElPayment}
            handleClosePayment={() => {
              setAnchorElPayment(null);
            }}
            subsystem={
              post?.page_owner
                ? post?.page_owner?.title
                : post?.account?.display_name
            }
            discountData={discountData}
            exchangeSelected={exchangeSelected}
            setExchangeSelected={setExchangeSelected}
            data={{
              title: ''
            }}
            infoDonate={post}
            entity_type={'Status'}
            statusPayment="donate"
            setOpenDialogPaymentDetail={setOpenDialogPaymentDetail}
            action={data => handlePaymentDonate(data)}
            loading={loadingDonate}
            setLoading={setLoadingDonate}
          />
        )}
        {openDialogPaymentDetail && (
          <DialogPaymentDetail
            open={openDialogPaymentDetail}
            exchangeSelected={exchangeSelected}
            setOpenSnackbar={value => {}}
            setNoti={func => {}}
            setOpenDialogPaymentDetail={setOpenDialogPaymentDetail}
          />
        )}

        {anchorEl && (
          <Emoji
            iconSelect={e => {
              handleIconChange(e);
              inputRef.current?.focus();
            }}
            anchorEl={anchorEl}
            handleCloseEmoji={handleCloseEmoji}
          />
        )}

        {anchorElGif && (
          <Gif
            setTypeFocus={setTypeFocus}
            formik={formik}
            openPopper={openPopper}
            setOpenPopper={setOpenPopper}
            anchorEl={anchorElGif}
            setAnchorEl={setAnchorElGif}
            setGifSelected={setGifSelected}
            inputRef={inputRef}
          />
        )}

        {anchorElSticky && (
          <Sticky
            setTypeFocus={setTypeFocus}
            formik={formik}
            openPopper={openPopperSticky}
            setOpenPopper={setOpenPopperSticky}
            anchorEl={anchorElSticky}
            setAnchorEl={setAnchorElSticky}
            setStickySelected={setStickySelected}
            inputRef={inputRef}
          />
        )}

        {openDialogValidate && (
          <CustomizedDialogs
            title="Bình luận quá dài"
            text="Bình luận không được dài quá 8.000 ký tự. Hãy thử rút ngắn bình
            luận."
            open={openDialogValidate}
            handleClose={() => setOpenDialogValidate(false)}
            labelButton="Đóng"
          />
        )}
      </div>

      {(files.data.length > 0 ||
        formik.values.display_gift ||
        formik.values.display_sticky) && (
        <div className={classes.thumbsContainer}>{thumbs}</div>
      )}
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </>
  );
});

export default CommentBox;
