import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createPageApi, getPageCategoriesApi } from 'src/apis/socialPages.api';
import CustomPrompt from 'src/components/CustomPrompt';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import {
  pagePreview,
  resetListPage,
  updateHasMore
} from 'src/store/action/socialPageAction';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0px 10px 0px 5px !important',
    borderRadius: 10,
    width: '100%'
  },
  textHelp: {
    color: '#65676b',
    fontSize: 11,
    fontFamily: 'inherit'
  },
  noOptionsText: {
    fontSize: '15px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    color: '#8a8d91'
  },
  loadingBox: {
    width: '100%',
    maxHeight: '180px',
    height: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '10px',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)'
  }
}));

const typePagePurposeList = [
  {
    id: 'sell_products',
    name: 'Bán hàng',
    icon: 'fa-solid fa-shop'
  },
  {
    id: 'sell_courses',
    name: 'Bán khoá học',
    icon: 'fa-solid fa-book'
  },
  {
    id: 'page_content',
    name: 'Trang nội dung',
    icon: 'fa-solid fa-newspaper'
  }
];

const pageTypeList = [
  {
    id: 'personal',
    name: 'Cá nhân',
    icon: 'fa-solid fa-person'
  },
  {
    id: 'business',
    name: 'Doanh nghiệp',
    icon: 'fa-solid fa-buildings'
  }
];

const PageCreateUpdate = props => {
  const classes = useStyles();
  const match: any = useRouteMatch();
  const history = useHistory();
  const { createPage, setCreatePage, setCheckCreatePage } = props;
  const dispatch = useDispatch();
  const [categoriesOptions, setCategoriesOptions] = React.useState([] as any);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [verify, setVerify] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>('');
  const [pageType, setPageType] = React.useState(pageTypeList);
  const [typePagePurpose, setTypePagePurpose] =
    React.useState(typePagePurposeList);
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  const querySearchParams = new URLSearchParams(window.location.search);
  const typeCreate = querySearchParams.get('page_purpose');

  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const fetchCategoriesPage = async (params: any = '') => {
    let response = await getPageCategoriesApi(params);
    setCategoriesOptions(response.data);
  };
  const fieldCreateUpdatePage = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'Thông tin về trang',
      label: 'Thông tin về trang',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3
    },
    {
      field_type: 'autocomplete',
      name: 'page_purpose',
      options: typePagePurposeList,
      multiple: false,
      setOptions: setPageType,
      placeholder: 'Chọn loại Trang',
      label: 'Loại trang',
      descriptionLabel: 'Bắt buộc*',
      helpText: 'Bỏ trống nếu trang của bạn không kinh doanh bán hàng'
    },
    {
      field_type: 'autocomplete',
      name: 'page_type',
      options: pageTypeList,
      multiple: false,
      setOptions: setTypePagePurpose,
      placeholder: 'Chọn loại hình kinh doanh',
      descriptionLabel: 'Bắt buộc*',
      label: 'Loại hình kinh doanh'
    },
    {
      field_type: 'autocomplete',
      name: 'page_category_ids',
      options: categoriesOptions,
      multiple: true,
      searchParams: true,
      fetchData: fetchCategoriesPage,
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Hạng mục',
      label: 'Hạng mục',
      renderListTag: true
    },
    {
      field_type: 'textField',
      name: 'description',
      label: 'Mô tả',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả ngắn',
      rows: 4,
      onChange: true
    },
    {
      field_type: 'upload_media',
      name: 'avatar',
      label: 'Ảnh đại diện',
      type: 'banner',
      // type_field: 'binary',
      height: 160
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'banner',
      // type_field: 'binary',
      height: 160
    }
  ];

  const createUpdatePageAction = async data => {
    let response;
    try {
      response = await createPageApi(data);
      if (response.status === 200) {
        setNotificationLeave(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Tạo mới trang thành công'
        });
        // reset lại list page để thực hiện hành động getListMyPageReq bên Main Layout
        // Để lấy danh sách mới khi 1 page được tạo. Nếu không cần f5
        // Sau khi reset ListPage cần set hasMore về true để call API khi loadMore
        dispatch(resetListPage());
        dispatch(updateHasMore(true));

        const url =
          typeCreate === 'sell_products' ||
          formik.values.page_purpose.id === 'sell_products'
            ? `/marketplace/shop/welcome_market?page_id=${response.data?.id}`
            : `/page/${response.data?.id}`;
        history.push(url);

        setCreatePage(false);
      } else {
        setOpenSnackbar(true);
        setNoti({
          code: 400,
          message: 'Lỗi, vui lòng thử lại sau'
        });
        setCreatePage(false);
      }
    } catch (error: any) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: error?.response?.data?.error || 'Lỗi, vui lòng thử lại sau'
      });

      setCreatePage(false);
    }
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .min(2, 'Tên của trang phải lớn hơn 2 ký tự.')
      .max(75, 'Tên của trang tối đa 75 ký tự')
      .test(
        'doesNotStartWithSpecialCharacter',
        'Ký tự đầu không được là ký tự đặc biệt',
        (value: any) => {
          if (!value) return true; // Nếu value là undefined hoặc null, bỏ qua kiểm tra này
          const firstCharacter = value.charAt(0);
          return !/[!@#$%^&*(),.?":{}|<> ]/.test(firstCharacter);
        }
      ),

    page_category_ids: Yup.array().max(
      3,
      'Mỗi trang chỉ được có từ 1 đến 3 hạng mục.'
    ),
    description: Yup.string()
      .min(5, 'Không được để trống, tối thiểu 5 kí tự')
      .max(255, 'Không được vượt quá 255 ký tự.'),
    avatar: Yup.mixed().test(
      'imageDimensions',
      'Vui lòng chọn ảnh ít nhất từ 450x150 pixels',
      value => {
        if (!value) {
          return true;
        }

        if (
          value?.file &&
          (value?.file instanceof File || value?.file instanceof Blob)
        ) {
          const image: any = new Image();
          image.src = value?.file ? URL.createObjectURL(value.file) : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        return true;
      }
    ),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Vui lòng chọn ảnh ít nhất từ 450x150 pixels',
      value => {
        if (!value) {
          return true;
        }
        if (
          value?.file &&
          (value?.file instanceof File || value?.file instanceof Blob)
        ) {
          const image = new Image();
          image.src = value?.file ? URL.createObjectURL(value.file) : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.naturalWidth;
              const height = image.naturalHeight;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }
        return true;
      }
    )
  });
  const formik = useFormik({
    initialValues: {
      title: '',
      page_purpose: '',
      page_type: '',
      description: '',
      page_category_ids: [],
      avatar: null,
      banner: null as any
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      if (createPage) {
        let data = {
          ...values,
          banner: values.banner
            ? {
                id: values.banner?.id ?? ''
              }
            : null,
          avatar: values.avatar
            ? {
                id: values.avatar?.id ?? '',

                status: values.avatar.status ?? ''
              }
            : null,
          page_category_ids: values.page_category_ids
            ? values.page_category_ids.map((el: any) => el.id)
            : []
        };
        createUpdatePageAction(data);
      }
    }
  });
  const handleOnChangeWarning = (event: React.FormEvent) => {
    const formData: any = new FormData(event.currentTarget as HTMLFormElement);
    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value: any) => value.length || (value as File).size
    );
    setNotificationLeave(changedFields.length > 0);
  };
  React.useEffect(() => {
    if (typeCreate && typeCreate === 'sell_products') {
      formik.setFieldValue('page_purpose', typePagePurposeList[0]);
    }
  }, [typeCreate]);

  React.useEffect(() => {
    formik.handleSubmit();
  }, [createPage]);

  React.useEffect(() => {
    fetchCategoriesPage();
  }, []);

  React.useEffect(() => {
    dispatch(
      pagePreview({
        title: formik.values.title,
        description: formik.values.description,
        page_category_ids: formik.values.page_category_ids,
        avatar: formik.values.avatar,
        banner: formik.values.banner
      })
    );
  }, [formik.values, formik.values?.banner?.show_url]);

  React.useEffect(() => {
    if (
      formik.values.title.trim() &&
      formik.values.page_category_ids.length > 0 &&
      formik.values.description &&
      formik.values.page_purpose &&
      Object.keys(formik.errors).length === 0
    ) {
      setCheckCreatePage(true);
    } else {
      setCheckCreatePage(false);
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  return (
    <div className={classes.root}>
      <CustomPrompt
        when={notificationLeave}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return notificationLeave;
        }}
        title={'Rời khỏi trang?'}
        content={'Thay đổi chưa lưu của bạn sẽ bị mất nếu bạn rời khỏi trang.'}
        optionDialog={2}
        labelButton={['Ở lại', 'Rời khỏi']}
      />
      <form onChange={handleOnChangeWarning}>
        <SocialFieldUpdate
          match={match}
          setVerify={setVerify}
          verify={verify}
          setLoading={setLoading}
          formik={formik}
          fieldUpdate={fieldCreateUpdatePage}
          spacing={0}
          notificationLeave={notificationLeave}
          setNotificationLeave={setNotificationLeave}
        />
      </form>
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
};
export default PageCreateUpdate;
