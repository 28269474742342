import { Alert, Divider, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import InputTextField from 'src/components/Input/SocialInput/InputTextField';
import './signin.css';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailed, loginReq, messageFail } from 'src/store/action/login';
import { useGoogleLogin } from '@react-oauth/google';
import { signInGoogle, configs } from 'src/apis/user.api';
import { buttonColor } from 'src/constants/styles';
import { updateTokenUser } from 'src/util/config';
import * as Yup from 'yup';
import DialogBlockRegister from 'src/components/Dialog/DialogBlockRegister';
import Emso from 'src/assets/images/logoEmso/logoEmso.png';
import logo from 'src/assets/images/logoEmso/logo.png';

const FormSignIn = ({
  setIsForgetPassword,
  classes,
  setOpenRegister,
  query
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isError = useSelector((state: any) => state.loginReducer.isError);
  const messageFailLogin = useSelector(
    (state: any) => state.loginReducer.messageFail
  );
  const [errorEmail, setErrorEmail] = React.useState<any>('');
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [register, setRegister] = React.useState<boolean>(true);

  const handleSubmit = (username, password) => {
    dispatch(loginReq(username, password));
  };
  useEffect(() => {
    fetchConfig();
  }, []);
  const fetchConfig = async () => {
    const response = await configs();
    if (response.status === 200) {
      if (response?.data?.register === 'false') {
        setRegister(false);
      }
    }
  };
  const login = useGoogleLogin({
    onSuccess: response => {
      handleSignInGoogle({ access_token: response.access_token });
    },
    onError: error => {
      console.error('Login Failed:', error);
    },
    flow: 'implicit' // Quan trọng: Đảm bảo sử dụng implicit flow
  });

  const handleSignInGoogle = data => {
    signInGoogle(data)
      .then(response => {
        if (response.status === 200 && response.data.access_token) {
          localStorage.setItem('token', response.data.access_token);
          updateTokenUser(response.data.access_token);
          history.push('/');
        }
      })
      .catch(error => {
        dispatch(loginFailed({ isError: true }));
        setErrorEmail('Đăng nhập bằng Gmail bị lỗi, vui lòng thử lại sau');
      });
  };

  const handleButtonClick = () => {
    if (register === false) {
      setOpenDialog(true);
    } else {
      login(); // Safari sẽ chặn nếu gọi gián tiếp
    }
  };

  let type = query.get('type');

  const renderAlert = text => {
    return (
      <Grid item xs={12}>
        <Alert severity="info">
          <Typography style={{ fontSize: 15, fontStyle: 'italic' }}>
            {text}
          </Typography>
        </Alert>
      </Grid>
    );
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .max(100, 'Tài khoản không quá 100 ký tự!')
      .required('Tài khoản không được để trống!'),
    password: Yup.string()
      .max(50, 'Mật khẩu không quá 50 ký tự!')
      .required('Mật khẩu không được để trống!')
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: values => {
      dispatch(
        loginFailed({
          isError: false
        })
      );
      dispatch(messageFail(null));
      handleSubmit(values.username, values.password);
    }
  });

  React.useEffect(() => {
    dispatch(
      loginFailed({
        isError: false
      })
    );
    dispatch(messageFail(null));
  }, []);

  React.useEffect(() => {
    if (type === 'confirmed') {
      let dataRegistration: any = localStorage.getItem('emailRegistration');
      if (dataRegistration !== 'undefined') {
        formik.setFieldValue('username', JSON.parse(dataRegistration)?.email);
      }
    }
  }, []);

  return (
    <Grid container spacing={2}>
      {type === 'reset'
        ? renderAlert(
            'Đổi mật khẩu thành công. Đăng nhập lại để tiếp tục sử dụng bạn nhé!'
          )
        : type === 'registration'
        ? renderAlert(
            'Chúng tôi đã gửi tin nhắn yêu cầu xác thực tài khoản vào hòm thư Email của bạn, vui lòng kiểm tra và làm theo hướng dẫn.'
          )
        : type === 'confirmed'
        ? renderAlert(
            'Xác thực tài khoản mail thành công, đăng nhập lại để tiếp tục sử dụng bạn nhé.'
          )
        : null}
      {type === 'registration' ? null : (
        <>
          <Grid item xs={12}>
            <InputTextField
              formik={formik}
              placeholder="Email hoặc số điện thoại"
              autoComplete="email"
              id="email"
              // autoFocus
              required
              field="username"
              onBlur={() => {
                formik.setFieldTouched('new_password', false);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputTextField
              formik={formik}
              placeholder="Mật khẩu"
              autoComplete="current-password"
              id="password"
              required
              field="password"
              type="password"
              onBlur={() => {
                formik.setFieldTouched('new_password', false);
              }}
            />
          </Grid>
          {isError ? (
            <Grid item xs={12}>
              <Alert sx={{ width: '100% !important' }} severity="error">
                <Typography style={{ fontSize: 15, fontStyle: 'italic' }}>
                  {errorEmail
                    ? errorEmail
                    : messageFailLogin?.error &&
                      !messageFailLogin?.error_description
                    ? 'Tài khoản của bạn đã bị vô hiệu hóa,vui lòng thử lại sau.'
                    : 'Tài khoản hoặc mật khẩu không đúng, vui lòng kiểm tra lại.'}
                </Typography>
              </Alert>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <ButtonInherit
              label="Đăng nhập"
              color={buttonColor.backgroundColor}
              fullWidth
              disabled={!formik.isValid || !formik.dirty}
              style={{
                marginRight: 0,
                height: 42,
                backgroundColor: buttonColor.backgroundColor
              }}
              action={() => formik.submitForm()}
              styleHover={{
                backgroundColor: '#7d72df'
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <ButtonInherit
          action={handleButtonClick}
          label="Đăng ký/Đăng nhập bằng Google"
          icon={<i className="fa-brands fa-google"></i>}
          fullWidth
          style={{
            marginRight: 0,
            height: '42px',
            backgroundColor: '#E4E6EB',
            '&:hover': {
              backgroundColor: '#d5dadf'
            }
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item justifyContent="space-between" xs={12}>
        <Grid item xs={6}>
          <span
            onClick={() => setIsForgetPassword(true)}
            className={classes.buttonLink}
            style={{ color: buttonColor.backgroundColor }}
          >
            Quên mật khẩu?
          </span>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={6}>
          {/* <span
            className={classes.buttonLink}
            onClick={() => setOpenRegister(true)}
            style={{ color: buttonColor.backgroundColor }}
          >
            Đăng ký tài khoản
          </span> */}
        </Grid>
      </Grid>
      {openDialog && (
        <DialogBlockRegister
          title="Cảm ơn bạn vì đã đăng ký trải nghiệm EMSO - Mạng xã hội vì người Việt!"
          text={`Nhằm tối ưu trải nghiệm người dùng, trong giai đoạn triển khai thử nghiệm hệ thống, trong một vài <br />
            khoảng thời gian nhất định, chúng tôi sẽ chặn tính năng đăng ký tài khoản mới nhằm tối ưu hệ thống để <br />
            đem lại trải nghiệm Tuyệt vời nhất cho người dùng. <br />
            Với những người dùng đã có tài khoản - có thể đăng nhập email và password đã cài đặt trong phiên <br />
            đăng nhập trước. <br />
            Chúng tôi tin tưởng, EMSO sẽ là Mạng xã hội đem lại nhiều lợi ích nhất cho người Việt Nam chúng ta. <br />
            Và chúng tôi cũng tin tưởng, bạn sẽ thông cảm với những bất tiện nho nhỏ này và sớm quay lại, trở thành <br />
            EMSOER và cùng chúng tôi xây dựng một Mạng xã hội - Thương mại điện tử với vô vàn lợi ích.`}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          icon={[Emso, logo]}
        />
      )}
    </Grid>
  );
};

export default FormSignIn;
