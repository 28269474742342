import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  Box,
  Stack
} from '@mui/material';

import { Link } from 'react-router-dom';
import group_cover from 'src/assets/images/group_cover.png';

import Sidebar from '../Sidebar/Sidebar';
import InputSearch from '../Input/SocialInput/InputSearch';
import ClickOutHandler from 'react-onclickout';
import {
  buttonColor,
  lineClamp,
  WebkitScrollbarRGB
} from 'src/constants/styles';
import Button from '../Button/Button';
import DialogShotCut from './DialogShotCut';
import { shortcutListApi } from 'src/apis/socialPost.api';
import { SkeletonFriendItem } from 'src/components/Skeleton/skeleton';
import ButtonInherit from '../Button/ButtonInherit';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 'calc(100vh - 48px)',
    overflow: 'hidden',
    width: '100vw',
    zIndex: 1200,
    position: 'absolute',
    // bottom: 0,
    left: 0,
    top: 56,
    display: 'flex'
  },
  wrapMenu: {
    display: 'flex',
    borderRight: '1px solid rgba(0,0,0,0.05)',
    boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
  },
  sideBar: {
    height: '100vh',
    width: 50,
    borderRight: '1px solid rgba(0,0,0,0.10)'
  },
  menu: {
    width: '360px',
    height: '100vh',
    position: 'fixed',
    marginLeft: '50px',
    backgroundColor: theme.palette.background.backgroundShortCut,
    zIndex: '1000'
  },
  noContent: {
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.modalBackdrop,
    position: 'fixed',
    marginLeft: '50px'
  },
  wrapMenuContent: {
    padding: '15px'
  },
  list: {
    width: '100%'
  },
  textPrimary: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    wordBreak: 'break-word',
    ...(lineClamp(1) as any)
  },
  avatar: {
    width: '40px !important',
    height: '40px !important',
    borderRadius: 8
  },
  wrapGroup: {
    padding: '4px',
    margin: '4px 0px',
    height: 'calc(100vh - 260px)',
    overflowY: 'auto',
    ...WebkitScrollbarRGB({})
  },
  link: {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    textDecoration: 'none'
  }
}));

const ShortCutSideBar = props => {
  const { isOpenShortCut, setIsOpenShortCut } = props;
  const classes = useStyles();
  const [shortcutList, setShortcutList] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [keyword, setKeyword] = React.useState<string>('');
  const keywordLower: string = keyword.toLowerCase();

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
    document.body.classList.add('dialog-open');
  };
  const handleClose = () => {
    setOpenDialog(false);
    document.body.classList.remove('dialog-open');
  };

  const fetchShortcutList = async () => {
    let response = await shortcutListApi('GET', null);
    if (response.status === 200) {
      setShortcutList(response.data);
      setIsLoading(false);
      setOpenDialog(false);
      // document.body.classList.remove('dialog-open');
    }
  };
  const dialogRef = useRef(null);
  React.useEffect(() => {
    fetchShortcutList();
  }, []);

  const renderListGroups = () => {
    if (!shortcutList.length) {
      return (
        <SkeletonFriendItem widthAndHeightAvatar={50} widthTextTop={180} />
      );
    }

    return (
      <List
        className={classes.list}
        component="nav"
        aria-label="sidebar"
        onClick={() => setIsOpenShortCut(false)}
      >
        {shortcutList
          .filter(
            shortcut =>
              (shortcut?.page?.title || shortcut?.group?.title)
                ?.toLowerCase()
                ?.includes(keywordLower) && shortcut.status !== 'hide'
          )
          .map((item: any, index) => (
            <Link
              key={index}
              className={classes.link}
              to={
                item.group
                  ? `/group/${item?.group?.username ?? item?.group?.id}`
                  : `/page/${item?.page.id ?? item?.page.username}`
              }
            >
              <ListItem
                style={{ borderRadius: 10, height: 68 }}
                button
                key={item?.id}
              >
                <ListItemIcon sx={{ minWidth: 42 }}>
                  <Avatar
                    variant="rounded"
                    className={classes.avatar}
                    src={
                      item?.group
                        ? item?.group?.banner?.show_url ??
                          item?.group?.banner?.url
                        : item?.page
                        ? item?.page?.avatar_media?.show_url ??
                          item?.page?.avatar_media?.url
                        : group_cover
                    }
                  ></Avatar>
                </ListItemIcon>

                <ListItemText
                  style={{ marginLeft: 10 }}
                  primary={
                    <Typography className={classes.textPrimary}>
                      {item?.group ? item.group.title : item.page.title}
                    </Typography>
                  }
                />
              </ListItem>
            </Link>
          ))}
      </List>
    );
  };

  return (
    <>
      <Box className={classes.root}>
        <Box
          className={classes.wrapMenu}
          sx={{ backgroundColor: 'background.primary' }}
        >
          <Box className={classes.sideBar}>
            <Sidebar
              type="shortCutMenu"
              isOpenShortCut={isOpenShortCut}
              setIsOpenShortCut={setIsOpenShortCut}
            />
          </Box>
          <ClickOutHandler
            onClickOut={event => {
              if (
                openDialog ||
                (dialogRef.current && dialogRef.current?.contains(event.target))
              ) {
                return;
              }
              setIsOpenShortCut(false);
            }}
          >
            <Box className={classes.menu}>
              <div className={classes.wrapMenuContent}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: 700, fontSize: 24 }}>
                    Lối tắt
                  </Typography>
                  <ButtonInherit
                    color={buttonColor.backgroundColor}
                    action={handleClickOpen}
                    style={{ width: '150px' }}
                    label="Chỉnh sửa"
                  />
                </Stack>
                <Typography
                  style={{
                    fontSize: 16,
                    color: 'text.secondary',
                    margin: '8px 0px'
                  }}
                >
                  Với lối tắt, bạn có thể nhanh chóng truy cập vào những việc
                  mình hay làm nhất trên Emso Social, giúp cho bạn có được trải
                  nghiệm tốt nhất, nhanh nhất khi sử dụng.
                </Typography>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm Trang, nhóm,..."
                />
              </div>
              <Divider />

              <div className={classes.wrapGroup}>{renderListGroups()}</div>
            </Box>
          </ClickOutHandler>
        </Box>
        <div className={classes.noContent}></div>
      </Box>

      <DialogShotCut
        ref={dialogRef}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        fetchShortcutList={fetchShortcutList}
        open={openDialog}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        shortcutList={shortcutList}
      />
    </>
  );
};

export default ShortCutSideBar;
