import ReactGA from 'react-ga4';
import { ga_measurement_id } from './config';

const GA_MEASUREMENT_ID = ga_measurement_id; // Thay bằng ID Google Analytics của bạn

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = path => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

export const logEvent = (category, action, label = '') => {
  ReactGA.event({
    category,
    action,
    label
  });
};
