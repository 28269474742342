import React, { useEffect, useState } from 'react';
import Routes from 'src/routes/routes';
import { CssBaseline } from '@mui/material';
import { withTheme } from '@mui/styles';
import './App.css';
import '../assets/scss/myScss/app.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from 'src/constants/common';
import { SnackbarProvider } from 'notistack';
import { initGA, logPageView } from 'src/util/analytics';
import { useLocation } from 'react-router-dom';

function App() {
  const token = localStorage.getItem('token');
  const [lastHiddenTime, setLastHiddenTime] = useState<number | null>(null);
  const location = useLocation();

  // Chỉ khởi tạo Google Analytics một lần duy nhất
  useEffect(() => {
    initGA();
  }, []);

  // Gửi log mỗi khi route thay đổi
  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  // Đặt lại scrollRestoration thành "manual" để tránh lỗi scroll khi điều hướng
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  // Đặt mặc định darkThemeUser nếu không có token
  useEffect(() => {
    if (!token) {
      localStorage.setItem('darkThemeUser', 'light');
    }
  }, [token]);

  // Reload sau 10 phút không hoạt động
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setLastHiddenTime(Date.now());
      } else if (lastHiddenTime) {
        const hiddenDuration = Date.now() - lastHiddenTime;
        if (hiddenDuration >= 10 * 60 * 1000) {
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [lastHiddenTime]);

  return (
    <div className="app">
      <SnackbarProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <CssBaseline />
          <Routes />
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </div>
  );
}

export default withTheme(App);
