import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import imageDefault from 'src/assets/images/group_cover.png';
import {
  convertISO8086toLTime,
  formatDateTimeToStringDateTimeYear
} from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import angryStaticUrl from 'src/assets/images/iconEmojiStatic/angry.png';
import hahaStaticUrl from 'src/assets/images/iconEmojiStatic/haha.png';
import loveStaticUrl from 'src/assets/images/iconEmojiStatic/love.png';
import sadStaticUrl from 'src/assets/images/iconEmojiStatic/sad.png';
import yayStaticUrl from 'src/assets/images/iconEmojiStatic/yay.png';
import wowStaticUrl from 'src/assets/images/iconEmojiStatic/wow.png';
import likeStaticUrl from 'src/assets/images/iconEmojiStatic/like.png';

const useStyles = makeStyles((theme: any) => ({
  rootCard: {
    width: '100% !important',
    height: '90px',
    marginLeft: '0px !important',
    padding: '0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  boxImage: {
    width: '100%',
    height: '70px',
    objectFit: 'cover',
    borderRadius: '4px'
  },
  boxCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  boxContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  boxItem: {
    width: '100%',
    height: '100px',
    backgroundColor: '#cccccc6e',
    borderRadius: '10px',
    padding: '10px'
  },
  boxReaction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  boxIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '10px'
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  boxText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    overflowWrap: 'break-word'
  }
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '700px !important'
  },
  '& .MuiDialogContent-root': {
    // overflow: 'auto',
    padding: '10px 10px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  handleClose: () => void;
  open: boolean;
  post: any;
}

export default function DialogShowPost(props: Props) {
  const { open, handleClose, post } = props;
  const history = useHistory();
  const classes = useStyles();

  const listInfoPost = [
    {
      id: 'count_show',
      count: post?.impressions,
      title: 'Lượt hiển thị bài viết'
    },
    {
      id: 'count_people',
      count: post?.people_reached,
      title: 'Số người bài viết tiếp cận'
    },
    {
      id: 'count_interact',
      count: post?.engagement,
      title: 'Lượt tương tác'
    }
  ];

  const listReactions = [
    {
      id: 'count_reaction',
      title: 'Lượt bày tỏ cảm xúc',
      count: post?.favourites?.length,
      icon: 'fa-solid fa-face-smile'
    },
    {
      id: 'count_comment',
      title: 'Bình luận',
      count: post?.replies_count,
      icon: 'fa-solid fa-message'
    },
    {
      id: 'count_share',
      title: 'Lượt chia sẻ',
      count: post?.reblogs_count,
      icon: 'fa-solid fa-share'
    },
    {
      id: 'count_click',
      title: 'Lượt click khác',
      count: post?.link_clicks,
      icon: 'fa-solid fa-arrow-pointer'
    }
  ];

  const listIconReact = [
    {
      id: 1,
      icon: likeStaticUrl,
      count_icon: post?.reactions[0]?.likes_count
    },
    {
      id: 2,
      key: 'icon_love',
      icon: loveStaticUrl,
      count_icon: post?.reactions[3]?.loves_count
    },
    {
      id: 3,
      key: 'icon_proud',
      icon: yayStaticUrl,
      count_icon: post?.reactions[6]?.yays_count
    },
    {
      id: 4,
      key: 'icon_haha',
      icon: hahaStaticUrl,
      count_icon: post?.reactions[1]?.hahas_count
    },
    {
      id: 5,
      icon: wowStaticUrl,
      count_icon: post?.reactions[5]?.wows_count
    },
    {
      id: 6,
      icon: sadStaticUrl,
      count_icon: post?.reactions[4]?.sads_count
    },
    {
      id: 7,
      icon: angryStaticUrl,
      count_icon: post?.reactions[2]?.angrys_count
    }
  ];

  const renderBody = () => {
    let postMedia = post?.media_attachments?.length
      ? post?.media_attachments
      : post?.reblog?.media_attachments;
    return (
      <Grid container spacing={2} className={classes.rootCard}>
        {postMedia?.length ? (
          <Grid item xs={3}>
            <img
              className={classes.boxImage}
              src={postMedia[0]?.preview_url}
              alt="image_post"
              onError={(e: any) => (e.target.src = imageDefault)}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={postMedia?.length ? 9 : 12}
          className={classes.boxContent}
          flexDirection={'column'}
        >
          <Typography
            style={{ fontSize: '16px' }}
            className={classes.lineClamp}
          >
            {post?.content}
          </Typography>
          <Typography style={{ fontSize: '14px' }}>
            Người đăng: {post?.account?.display_name}.{' '}
            {formatDateTimeToStringDateTimeYear(post?.backdated_time)} lúc{' '}
            {convertISO8086toLTime(post?.backdated_time)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold' }}
            textAlign={'center'}
          >
            Thông tin chi tiết về bài viết
          </Typography>
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          {renderBody()}
          <Grid
            container
            spacing={2}
            sx={{ marginTop: '15px', marginLeft: '0px', width: '100%' }}
          >
            {listInfoPost.map((item, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <Box className={classes.boxItem}>
                    <Typography sx={{ fontSize: '14px' }}>
                      {item.title}
                    </Typography>
                    <Typography sx={{ fontWeight: '500' }}>
                      {item.count}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box>
            <Typography
              sx={{
                padding: '16px',
                fontSize: '18px',
                fontWeight: '500'
              }}
            >
              Lượt tương tác
            </Typography>

            <Box className={classes.boxIcon}>
              {listIconReact.map((item, index) => {
                return (
                  <Box className={classes.itemIcon} key={index}>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '44px'
                      }}
                    >
                      <img
                        src={item.icon}
                        alt="icon"
                        style={{
                          width: `${
                            !item?.key //điều kiện cho includes
                              ? 30
                              : ['icon_love', 'icon_proud'].includes(item?.key)
                              ? 44
                              : item?.key === 'icon_haha'
                              ? 36
                              : 30
                          }px`,
                          height: `${
                            !item?.key //điều kiện cho includes
                              ? 30
                              : ['icon_love', 'icon_proud'].includes(item?.key)
                              ? 44
                              : item?.key === 'icon_haha'
                              ? 36
                              : 30
                          }px`
                        }}
                      />
                    </Box>
                    <Typography sx={{ paddingTop: '10px' }}>
                      {item.count_icon}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            {listReactions.map((el, index) => (
              <Box
                key={el.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 0px 10px 10px'
                }}
              >
                <i
                  className={el.icon}
                  style={{ fontSize: '22px', color: '#666666', width: '20px' }}
                ></i>
                <Box sx={{ paddingLeft: '20px', width: '100%' }}>
                  <Box className={classes.boxText}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                      {el.title}
                    </Typography>
                    <Typography>{el.count}</Typography>
                  </Box>
                  <Divider sx={{ marginTop: '10px' }} />
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            action={() => {
              history.push(`/page/${post?.page?.id}`);
            }}
            color={buttonColor.backgroundColor}
            style={{ fontSize: '14px' }}
            label={'Xem thông tin chi tiết Trang'}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
