import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import ImageRender from './ImageRender';

import ButtonInherit from 'src/components/Button/ButtonInherit';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import DialogEditMedia from './DialogEditMedia';

const useStyles = makeStyles((theme: any) => ({
  wrapVote: {
    borderRadius: 10,
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '8px',
    width: '100%',
    maxHeight: 650
  },
  wrapUpload: {
    width: '100%',
    borderRadius: 10,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.custom,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.hover
    }
  },
  wrapImage: {
    width: '100%',
    borderRadius: 10,
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonVote: {
    zIndex: 9999,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.button.inherit.background,
    border: '1px solid rgba(0,0,0,0.12)',
    '&:hover': {
      cursor: 'pointer'
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    top: 8,
    right: 8
  },
  wrapButtonEditAdd: {
    zIndex: 20,
    position: 'absolute',
    top: 5,
    left: 10,
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    fontSize: '17px !important',
    fontWeight: '500 !important'
  },
  subText: {
    fontSize: '12px !important',
    color: '#80838a'
  },
  wrapIconText: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: theme.palette.button.primary.background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '300px'
  },
  wrapText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));

const CUImagePost = props => {
  const { handleClose, post, files, setFiles, type } = props;
  const classes = useStyles();
  const [openEditMedia, setOpenEditMedia] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [typeVideo, setTypeVideo] = React.useState<any>(type);
  const [checkHiddenBtn, setCheckHiddenBtn] = React.useState<any>(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      'image/jpeg,image/png,/.glb,video/mp4,video/avi,video/quicktime,video/Ogg,video/wmv,video/mov',
    onDrop: async (acceptedFiles: any) => {
      let filesState = [...files];
      let fileAccepts = await Promise.all(
        acceptedFiles.map(async (file, index) => {
          const url = URL.createObjectURL(file);
          if (file.type.includes('video')) {
            const video = document.createElement('video');
            video.src = url;

            await new Promise<void>((resolve, reject) => {
              video.onloadedmetadata = () => {
                Object.assign(file, {
                  preview: url,
                  width: video.videoWidth,
                  height: video.videoHeight,
                  position: filesState?.length
                    ? filesState?.length + 1
                    : index + 1
                });
                resolve();
              };
              video.onerror = reject;
            });
            setTypeVideo('newVideo');
            return file;
          } else if (file.type.includes('image')) {
            const image = new Image();
            image.src = url;

            await new Promise<void>((resolve, reject) => {
              image.onload = () => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  Object.assign(file, {
                    preview: url,
                    width: image.width,
                    height: image.height,
                    position: filesState?.length
                      ? filesState?.length + 1
                      : index + 1
                  });
                  resolve();
                };
              };
              image.onerror = reject;
            });

            return file;
          } else {
            let newFile = Object.assign(file, {
              preview: URL.createObjectURL(file)
            });
            return newFile;
          }
        })
      );

      let newFiles = filesState.concat(fileAccepts);
      let sortedNewFiles = newFiles.sort((a, b) => a.position - b.position);
      let hasVideoFiles = sortedNewFiles.some(el => el.type === 'video/mp4');
      let checkPostPage;
      if (hasVideoFiles) {
        checkPostPage = sortedNewFiles.filter(el => el.type === 'video/mp4');
        setCheckHiddenBtn(true);
      } else {
        checkPostPage = sortedNewFiles;
        setCheckHiddenBtn(false);
      }
      if (type === 'page') {
        if (hasVideoFiles && sortedNewFiles.length > 1) {
          setOpenSnackbar(true);
          setNoti({
            code: 400,
            message: 'Rất tiếc, bạn không thể thêm cả ảnh và video một lúc'
          });
        }
        setFiles(checkPostPage);
      } else {
        setCheckHiddenBtn(false);
        setFiles(sortedNewFiles);
      }
    },
    multiple: true
  });

  const resetInputFile = () => {
    const inputElement = document.querySelector('input[type="file"]');
    if (inputElement) {
      inputElement.value = '';
    }
  };
  const removeFile = e => {
    e.stopPropagation();
    handleClose();
    setFiles([]);
    resetInputFile();
  };

  React.useEffect(() => {
    if (files.length === 0) {
      resetInputFile();
    }
  }, [files]);
  return (
    <div className={classes.wrapVote}>
      <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className={classes.wrapUpload}
          key={files.length}
        >
          {files.length ? (
            <Box sx={{ width: '100%', position: 'relative' }}>
              {!['account_banner', 'account_avatar'].includes(
                post?.post_type
              ) ? (
                <div onClick={removeFile} className={classes.buttonVote}>
                  <i className="far fa-times"></i>
                </div>
              ) : null}

              <div className={classes.wrapImage}>
                {!['account_banner', 'account_avatar'].includes(
                  post?.post_type
                ) ? (
                  <div className={classes.wrapButtonEditAdd}>
                    <ButtonInherit
                      action={e => {
                        e.stopPropagation();
                        setOpenEditMedia(true);
                      }}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-pen fa-sm"></i> &nbsp;
                          <span>Chỉnh sửa</span>
                        </div>
                      }
                    />

                    {type === 'page' && checkHiddenBtn ? null : (
                      <ButtonInherit
                        action={e => {
                          e.stopPropagation(); // Ngăn sự kiện lan tới Dropzone
                          const inputElement =
                            document.querySelector('.dropzone input');
                          if (inputElement) {
                            inputElement.click(); // Kích hoạt input mà không đụng đến Dropzone
                          }
                        }}
                        label={
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            {...getRootProps({ className: 'dropzone' })}
                          >
                            <i className="fas fa-file-plus fa-sm"></i> &nbsp;
                            <span>Thêm ảnh/video</span>
                          </div>
                        }
                      />
                    )}
                  </div>
                ) : null}
                {files.length > 0 && (
                  <ImageRender
                    type="create-upload"
                    images={files?.map((el: any) => {
                      return el.preview ?? el.preview_url;
                    })}
                    imagesType={files?.map((el: any) => el.type)}
                    medias={files}
                    typeAction={typeVideo}
                  />
                )}
                {!['account_banner', 'account_avatar'].includes(
                  post?.post_type
                ) ? (
                  <input {...getInputProps()} />
                ) : null}
              </div>
            </Box>
          ) : (
            <div className={classes.wrapRoot}>
              <div onClick={removeFile} className={classes.buttonVote}>
                <i className="far fa-times"></i>
              </div>
              <Box className={classes.wrapIconText}>
                <i className="fas fa-file-plus fa-lg"></i>
              </Box>
              <Box className={classes.wrapText}>
                <Typography className={classes.text}>Thêm ảnh/video</Typography>
                <Typography className={classes.subText}>
                  hoặc kéo và thả
                </Typography>
              </Box>
            </div>
          )}
          <input {...getInputProps()} />
        </div>
      </Box>

      <DialogEditMedia
        files={files}
        setFiles={setFiles}
        open={openEditMedia}
        handleClose={() => setOpenEditMedia(false)}
      />
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
};

export default CUImagePost;
