import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import Footer from '../Footer';
import SocialCutImage from '../SocialCutImage/SocialCutImage';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: 300,
        height: 'calc(100% - 50px)',
        overflow: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '6px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 6
        },
        position: 'fixed'
      },
      list: {
        width: '100%'
      },
      avatar: {
        width: '30px !important',
        height: '30px !important',
        marginLeft: '-1px'
      },
      listItem: {
        height: 40,
        paddingLeft: '2px !important'
      },
      listSelected: {
        height: 40,
        paddingLeft: '2px !important',
        backgroundColor: '#e7f3ff !important',
        borderRadius: '8px !important'
      },
      text: {
        fontSize: '15px',
        fontWeight: '500 !important'
      }
    }),
  { index: 1 }
);

function Sidebar(props) {
  const { isOpenShortCut, setIsOpenShortCut, type, setOpenVerifiedPass } =
    props;
  const classes = useStyles();
  const meInfo = useSelector((state: any) => {
    return state.meReducer.info;
  });
  const pagesAdmin = useSelector((state: any) => state.pageReducer.my_pages);
  const listConversations = useSelector(
    (state: any) => state.socialChatReducer.listConversations
  );
  const history = useHistory();
  const [currentPath, setCurrentPath] = React.useState<any>(
    history.location.pathname
  );
  const [newAccount, setNewAccount] = React.useState(false);
  const matches = useMediaQuery('(min-width:1001px)');
  const match = useRouteMatch();
  const dispatch = useDispatch();

  history.listen((location, action) => {
    setCurrentPath(location.pathname);
  });
  let userAvaUrl =
    meInfo?.avatar_media?.url ?? meInfo?.avatar_static ?? meInfo?.show_url;
  let userName = meInfo?.display_name ?? meInfo?.name;
  const theme = useTheme();

  useEffect(() => {
    checkDate();
  }, []);

  const checkDate = () => {
    const currentDate: any = new Date();
    const createdDate: any = new Date(meInfo.created_at);
    const timeDiff = currentDate - createdDate;
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    if (daysDiff <= 30) {
      setNewAccount(true);
    } else {
      setNewAccount(false);
    }
  };

  let listMenuOption = [
    {
      key: 'userSocial',
      image: userAvaUrl,
      label: userName,
      href: `/user/${meInfo.username ? meInfo.username : meInfo.id}`,
      path: [
        PATHS.USER,
        PATHS.ALBUM_DETAIL.replace(':url?', 'user'),
        PATHS.ALBUM_CREATE.replace(':url?', 'user'),
        PATHS.ALBUM_UPDATE.replace(':url?', 'user')
      ]
    },
    {
      key: 'momentSocial',
      label: 'Khoảnh khắc',
      typeImg: 'svg',
      positionY: 0,
      href: `/moment`,
      path: [
        PATHS.MOMENT,
        PATHS.MOMENT_TAG,
        PATHS.MOMENT_USER,
        PATHS.PAGE_HASHTAG,
        PATHS.MOMENT_SEARCH
      ]
    },
    {
      key: 'friendSocial',
      label: 'Bạn bè',
      href: '/friends',
      typeImg: 'svg',
      positionY: -225,
      path: [PATHS.FRIEND]
    },
    {
      key: 'welcomeSocial',
      label: 'Chào mừng',
      href: '/welcome',
      typeImg: 'svg',
      positionY: -537,
      path: [PATHS.WELCOME]
    },
    {
      key: 'groupSocial',
      typeImg: 'svg',
      positionY: -35,
      label: 'Nhóm',
      href: PATHS.GROUP,
      path: [
        PATHS.GROUP,
        PATHS.GROUP_POST_DETAIL,
        PATHS.GROUP_SEARCH,
        PATHS.GROUP_DETAIL,
        PATHS.GROUP_REQUEST,
        PATHS.GROUP_CATEGORY,
        PATHS.GROUP_DISCOVER,
        PATHS.GROUP_ACTIVITIES_USER,
        PATHS.ALBUM_DETAIL.replace(':url?', 'group'),
        PATHS.ALBUM_CREATE.replace(':url?', 'group'),
        PATHS.ALBUM_UPDATE.replace(':url?', 'group')
      ]
    },
    {
      key: 'watchSocial',
      typeImg: 'svg',
      positionY: -67,
      label: 'Watch',
      href: `/watch`,
      path: [
        PATHS.WATCH,
        PATHS.WATCH_PAGE,
        PATHS.WATCH_SHOW_DETAIL,
        PATHS.WATCH_SEARCH
      ]
    },
    {
      key: 'musicSpace',
      typeImg: 'svg',
      positionY: -435,
      label: 'Không gian âm nhạc',
      href: PATHS.MUSICSPACE,
      path: [
        PATHS.MUSICSPACE,
        PATHS.MUSICSPACE_CHART,
        PATHS.MUSICSPACE_NEW_CHART,
        PATHS.MUSICSPACE_ALBUM,
        PATHS.MUSICSPACE_SEARCH,
        PATHS.MUSICSPACE_MY_PLAYLIST,
        PATHS.MUSICSPACE_DETAIL_PLAYLIST,
        PATHS.MUSICSPACE_TRACK,
        PATHS.MUSICSPACE_DETAIL_SEARCH,
        PATHS.MUSICSPACE_NEW_RELEASE_SONG,
        PATHS.MUSICSPACE_NEW_RELEASE_ALBUM,
        PATHS.MUSICSPACE_CREATE_ALBUM,
        PATHS.MUSICSPACE_ARTIST,
        PATHS.MUSICSPACE_INVITE,
        PATHS.MUSICSPACE_DETAIL,
        PATHS.MUSICSPACE_DETAIL_ALBUM,
        PATHS.MUSICSPACE_UPLOADED,
        PATHS.MUSICSPACE_UPDATE_ALBUM,
        PATHS.MUSICSPACE_RANK_SONG,
        PATHS.MUSICSPACE_MUSIC_AUTHOR_LIST
      ]
    },
    {
      key: 'pageSocial',
      typeImg: 'svg',
      positionY: -256,
      positionX: 2,
      label: 'Trang',
      href: `${pagesAdmin?.length > 0 ? PATHS.PAGE : PATHS.PAGE_DISCOVER}`,
      path: [
        PATHS.PAGE,
        PATHS.PAGE_DETAIL,
        PATHS.PAGE_INVITES,
        PATHS.PAGE_LIKED,
        PATHS.PAGE_DISCOVER,
        PATHS.ALBUM_DETAIL.replace(':url?', 'page'),
        PATHS.ALBUM_CREATE.replace(':url?', 'page'),
        PATHS.ALBUM_UPDATE.replace(':url?', 'page')
      ]
    },
    {
      key: 'livestream',
      typeImg: 'svg',
      positionY: -296,
      label: 'Video trực tiếp',
      href: `/live/home`,
      path: [
        PATHS.LIVESTREAM_HOME,
        PATHS.LIVESTREAM_FOLLOW,
        PATHS.LIVESTREAM_SELL
      ]
    },
    {
      key: 'eventSocial',
      typeImg: 'svg',
      positionY: -633,
      label: 'Sự kiện',
      href: PATHS.EVENT,
      path: [
        PATHS.EVENT,
        PATHS.EVENT_DETAIL,
        PATHS.SCHEDULE_MANAGEMENT,
        PATHS.EVENT_MINE,
        PATHS.EVENT_CATEGORY
      ]
    },
    {
      key: 'growSocial',
      typeImg: 'svg',
      positionY: -159,
      label: 'Gọi vốn',
      href: PATHS.GROW,
      path: [
        PATHS.GROW,
        PATHS.GROW_CATEGORY,
        PATHS.GROW_MINE,
        PATHS.GROW_CREATE,
        PATHS.GROW_DETAIL
      ]
    },
    {
      key: 'recruitSocial',
      typeImg: 'svg',
      positionY: -194,
      label: 'Tuyển dụng',
      href: PATHS.RECRUIT,
      path: [
        PATHS.RECRUIT,
        PATHS.RECRUIT_CATEGORY,
        PATHS.RECRUIT_MINE,
        PATHS.RECRUIT_CREATE,
        PATHS.RECRUIT_DETAIL,
        PATHS.RECRUIT_CREATECV
      ]
    },
    {
      key: 'courseSocial',
      typeImg: 'svg',
      positionY: -96.2,
      label: 'Không gian học tập',
      href: PATHS.COURSE,
      path: [
        PATHS.COURSE,
        PATHS.COURSE_CATEGORY,
        PATHS.COURSE_MINE,
        PATHS.COURSE_CREATE,
        PATHS.COURSE_DETAIL
      ]
    },
    {
      key: 'marketPlace',
      typeImg: 'svg',
      positionY: -368,
      label: 'Không gian thương mại',
      href: PATHS.MARKETPLACE,
      path: [
        PATHS.MARKETPLACE,
        PATHS.MARKETPLACE_CATEGORY,
        PATHS.MARKETPLACE_MINE,
        PATHS.MARKETPLACE_SHOP,
        PATHS.PRODUCT_CREATE,
        PATHS.PRODUCT_DETAIL,
        PATHS.ORDER_DETAIL,
        PATHS.MANAGE_ORDER_DETAIL,
        PATHS.CATEGORY_GUIDE,
        PATHS.MARKETPLACE_TOP_PRODUCTS,
        PATHS.MARKETPLACE_FLASH_SALE,
        PATHS.MARKETPLACE_CAMPAIGN,
        PATHS.MARKETPLACE_BEST_SELLER,
        PATHS.MARKETPLACE_FULL_STAR,
        PATHS.MARKETPLACE_MALL,
        PATHS.MARKETPLACE_CART_RECOMMEND,
        PATHS.MARKETPLACE_EMSO_COMMIT
      ]
    },
    {
      key: 'historyPayment',
      typeImg: 'svg',
      positionY: -666,
      label: 'Đơn đặt hàng và thanh toán',
      action: () => {
        setOpenVerifiedPass(true);
      },
      href: currentPath,
      path: [
        PATHS.PAYMENT,
        PATHS.PAYMENT_MONEY,
        PATHS.PAYMENT_HISTORY,
        PATHS.PAYMENT_COIN,
        PATHS.RECURRING_PAYMENTS,
        PATHS.REQUEST_PAYMENTS,
        PATHS.REFUND_PAYMENTS,
        PATHS.SETTINGS_PAYMENT,
        PATHS.HELP_PAYMENT,
        PATHS.PAYMENT_TERMS,
        PATHS.PAYMENT_BANK
      ]
    },
    {
      key: 'saveSocial',
      typeImg: 'svg',
      positionY: -126,
      label: 'Đã lưu',
      href: PATHS.PAGE_SAVED,
      path: [PATHS.PAGE_SAVED]
    },
    {
      key: 'storyHistory',
      typeImg: 'svg',
      positionY: -402,
      label: 'Kỷ niệm',
      href: PATHS.CELEBRATE,
      path: [PATHS.CELEBRATE]
    },
    {
      key: 'fundSocial',
      typeImg: 'svg',
      positionY: -331,
      label: 'Hiến máu',
      href: PATHS.BLOOD_DONATION,
      path: [PATHS.BLOOD_DONATION]
    },
    {
      key: 'chat',
      typeImg: 'svg',
      positionY: -501,
      label: 'Trò chuyện',
      href:
        listConversations.length > 0
          ? `/messages/${listConversations[0]?.rid}`
          : '/messages',
      path: [PATHS.CHAT]
    },
    {
      key: 'linkShortcut',
      icon: 'fa-regular fa-link',
      label: 'Lối tắt của bạn',
      action: () => {
        setIsOpenShortCut(!isOpenShortCut);
      },
      href: currentPath
    }
  ];

  return (
    <Box
      className={classes.root}
      sx={
        !matches || type === 'shortCutMenu'
          ? {
              width: 50,
              padding: '0px 0px',
              backgroundColor: 'background.primary',
              borderRight:
                theme.palette.mode === 'dark'
                  ? '1px solid #f0f0f03e'
                  : '1px solid #3d3d3d2f'
            }
          : { backgroundColor: 'background.default' }
      }
    >
      <List className={classes.list} component="nav" aria-label="sidebar">
        {listMenuOption
          ?.filter((item: any) =>
            newAccount ? true : item.key !== 'welcomeSocial'
          )
          .map((item: any, index) => (
            <Tooltip
              key={index}
              title={item?.label || ''}
              disableHoverListener={
                !matches
                  ? matches
                  : type !== 'shortCutMenu' && matches
                  ? type !== 'shortCutMenu'
                  : undefined
              }
              placement="left"
            >
              <Link
                key={item.key}
                style={{
                  textDecoration: 'none',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                }}
                to={item?.href ? item.href : ''}
              >
                <ListItem
                  classes={{
                    root:
                      isOpenShortCut && item.key === 'linkShortcut'
                        ? classes.listSelected
                        : classes.listItem
                  }}
                  button
                  onClick={item.action && item.action}
                  sx={{
                    borderLeft:
                      item.path?.includes(match.path) ||
                      item.path?.includes(
                        match.path.replace(':url?', match?.params?.url)
                      )
                        ? '3px solid #2583ff'
                        : undefined,
                    paddingLeft: item.path?.includes(match.path)
                      ? '3px'
                      : undefined,
                    margin: '3px 0px'
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 48,
                      paddingLeft: '5px'
                    }}
                  >
                    {item.key === 'userSocial' ? (
                      <AvatarSocial
                        type="feed"
                        avatarObj={
                          meInfo?.avatar_media ?? meInfo?.avatar_static
                        }
                        style={{ width: 30, height: 30, marginLeft: '3px' }}
                      />
                    ) : item.icon ? (
                      <i
                        className={item.icon}
                        style={{
                          fontSize: 24,
                          paddingLeft: '3px',
                          color:
                            theme.palette.mode === 'dark' ||
                            (isOpenShortCut && item.key === 'linkShortcut')
                              ? buttonColor.backgroundColor
                              : '#050505'
                        }}
                      ></i>
                    ) : item?.typeImg === 'svg' ? (
                      <SocialCutImage
                        item={item}
                        image={
                          'https://s3.hn-1.cloud.cmctelecom.vn/sn-web/uploads/menu/IconMenu.png'
                        }
                      />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      matches || type !== 'shortCutMenu' ? (
                        <Typography className={classes.text}>
                          {item.label}{' '}
                          {item.key === 'userSocial' && meInfo.certified ? (
                            <span>
                              <Tooltip
                                placement="top-start"
                                title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                                arrow
                                PopperProps={{
                                  sx: {
                                    '& .MuiTooltip-tooltip': {
                                      fontSize: '14px',
                                      padding: '10px'
                                    }
                                  }
                                }}
                              >
                                <VerifiedIcon
                                  //titleAccess="Tài khoản đã xác minh"
                                  fontSize="small"
                                  viewBox="0 -2 24 24"
                                  sx={{
                                    color: buttonColor.backgroundColor,
                                    fontSize: '14px',
                                    '&:hover': {
                                      color: buttonColor.backgroundColor
                                    }
                                  }}
                                />
                              </Tooltip>
                            </span>
                          ) : null}
                          {item.key === 'userSocial' &&
                          !meInfo.certified &&
                          meInfo.identity_verification ? (
                            <span>
                              <Tooltip
                                placement="top-start"
                                title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                                arrow
                                PopperProps={{
                                  sx: {
                                    '& .MuiTooltip-tooltip': {
                                      fontSize: '14px',
                                      padding: '10px'
                                    }
                                  }
                                }}
                              >
                                <VerifiedIcon
                                  // titleAccess="Tài khoản đã xác minh"
                                  fontSize="small"
                                  viewBox="0 -2 24 24"
                                  sx={{
                                    color: 'hsla(0, 0%, 50%)',
                                    fontSize: '14px',
                                    '&:hover': {
                                      color: 'hsla(0, 0%, 60%)'
                                    }
                                  }}
                                />
                              </Tooltip>
                            </span>
                          ) : null}
                        </Typography>
                      ) : (
                        ''
                      )
                    }
                  />
                </ListItem>
              </Link>
            </Tooltip>
          ))}
      </List>
      <div
        style={
          !matches || type === 'shortCutMenu'
            ? { display: 'none' }
            : { display: 'block', width: '300px' }
        }
      >
        <Footer />
      </div>
    </Box>
  );
}

export default Sidebar;
