import { call, put, takeEvery } from 'redux-saga/effects';
import {
  uploadCommentAttachmentApi,
  uploadMediaApi,
  uploadVideoApi
} from 'src/apis/commonSocialApi';
import { getTimelineCourseApi } from 'src/apis/socialCourse.api';
import { getTimelineEventApi } from 'src/apis/socialEvent.api';
import {
  getPinnedPostGroup,
  getPostAllGroupApi,
  getSchedulePostGroupApi,
  groupTimelineApi
} from 'src/apis/socialGroup';
import { getTimelineGrowApi } from 'src/apis/socialGrow.api';
import {
  createPageAddress,
  getPinnedPostPage,
  getScheduledPostPage,
  pageTimelineApi
} from 'src/apis/socialPages.api';
import {
  createUpdatePostApi,
  crudBookmarkCollectionApi,
  deletePostApi,
  getDiscoverMomentApi,
  getDiscoverWatchApi,
  getListMomentApi,
  getListPostAccountApi,
  getListPostApi,
  getListPostFriendDiscoverApi,
  getListWatchApi
} from 'src/apis/socialPost.api';
import { getPinnedPostUser } from 'src/apis/socialUser';
import { GROUP_TYPE } from 'src/constants/groupType';
import { PAGE_TYPE } from 'src/constants/pageType';
import * as types from 'src/constants/store/socialPost';
import * as actionSaveCache from 'src/store/action/saveCacheAction';
import * as actionUser from 'src/store/action/saveUser';
import * as actionCourse from 'src/store/action/socialCourseAction';

import * as actionGroup from 'src/store/action/socialGroupAction';
import * as actionGrow from 'src/store/action/socialGrowAction';
import * as actionLive from 'src/store/action/socialLiveStreamAction';
import * as actions from 'src/store/action/socialPostAction';
import * as actionLife from 'src/store/action/socialUserAction';

const typesSetting: any = types;

export const getPostSocialSagas = function* ({ payload }) {
  try {
    const { params, type, path } = payload;
    let response;
    if (type === PAGE_TYPE.home) {
      yield put(actions.loadingApiPost(true));
      response = yield call(getListPostApi, params);
    } else if (type === PAGE_TYPE.user) {
      response = yield call(getListPostAccountApi, params.id, params);
    } else if (type === PAGE_TYPE.group || type === 'banner_group') {
      response = yield call(groupTimelineApi, params.id, {
        ...params,
        id: null
      });
    } else if (type === GROUP_TYPE.allGroup) {
      response = yield call(getPostAllGroupApi, params);
    } else if (type === 'page' && params.page_id) {
      response = yield call(pageTimelineApi, params.page_id, {
        ...params,
        id: null
      });
    } else if (type === 'event' && params.event_id) {
      response = yield call(getTimelineEventApi, params.event_id, {
        ...params,
        id: null
      });
    } else if (type === 'project' && params.project_id) {
      response = yield call(getTimelineGrowApi, params.project_id, {
        ...params,
        id: null
      });
    } else if (type === 'course' && params.course_id) {
      response = yield call(getTimelineCourseApi, params.course_id, {
        ...params,
        id: null
      });
    } else if (type === 'moment') {
      response = yield call(getListMomentApi, params);
    } else if (type === 'discover-moment') {
      response = yield call(getDiscoverMomentApi, params);
    } else if (type === 'watch-new') {
      response = yield call(getListWatchApi, params);
    } else if (type === 'watch-discover') {
      response = yield call(getDiscoverWatchApi, params);
    } else if (type === 'friend-discover') {
      response = yield call(getListPostFriendDiscoverApi, params);
    }

    if (
      response.status === 200 &&
      (path === window.location.href || path === 'update_moment')
    ) {
      let activities = response.data;

      yield put(actions.getPostSuccessAction(activities, type, path));
      yield;
    }
  } catch (error) {
    yield put(actions.loadingApiPost(false));
  }
};

async function uploadMedia(
  media,
  setState,
  type,
  typeEndPoint: 'market' | 'course' | 'others',
  position
) {
  let fileUpload = media.id
    ? {
        description: media.description,
        position: position ?? media.position
      }
    : media;
  const dataEndpoint = ['market', 'course'].includes(typeEndPoint)
    ? 'marketplace'
    : null;
  if (media.type?.includes('video') && !media.id && type !== 'livestream') {
    return await uploadVideoApi(
      media,
      setState,
      typeEndPoint,
      dataEndpoint,
      type
    );
  } else {
    if (type == 'comment') {
      return (await uploadCommentAttachmentApi(
        fileUpload,
        setState,
        media.id ? media.id : media?.type ? null : media
      )) as any;
    } else {
      return (await uploadMediaApi(
        fileUpload,
        setState,
        media.id ? media.id : media?.type ? null : media,
        typeEndPoint,
        position
      )) as any;
    }
  }
}

export const uploadMediaSaga = async function action(
  medias,
  type,
  typeEndPoint: 'market' | 'course' | 'others' = 'others'
) {
  let promises: any = [];
  medias?.map((el: any, idx: number) => {
    return promises.push(uploadMedia(el, '', type, typeEndPoint, idx + 1));
  });

  return Promise.all(promises).then(response => {
    return response?.map((el: any) => el.data.id);
  });
};

export const uploadMediasSaga = async function action(
  medias,
  setState,
  type,
  typeEndPoint: 'market' | 'course' | 'others'
) {
  let promises: any = [];
  medias?.map((el: any, idx: number) => {
    return promises.push(
      uploadMedia(el, setState, type, typeEndPoint, idx + 1)
    );
  });

  return Promise.all(promises).then(response => {
    return response?.map((el: any) => el.data);
  });
};

export const createPostRequested = function* (action) {
  let place = action.payload.place;
  try {
    let data = action.payload.data;
    let typePost = action.payload.type;
    let typeLive = data.post_type;
    let response;

    if (typeLive === 'livestream') {
      response = yield call(createUpdatePostApi, data, null);
    } else {
      if (data.media_ids?.length > 0) {
        const listIds = yield call(
          uploadMediaSaga,
          data.media_ids,
          data.post_type
        );
        if (listIds && listIds.length) {
          data['media_ids'] = listIds;
        }
        response = yield call(createUpdatePostApi, data, null);
      } else if (!data.place_id?.id && data.place_id?.title) {
        let res = yield call(createPageAddress, {
          title: data.place_id?.title,
          location: data.place_id?.location,
          is_place: true
        });
        if (res.status === 200) {
          data['place_id'] = res.data.id;
        }
        response = yield call(createUpdatePostApi, data, null);
      } else {
        response = yield call(
          createUpdatePostApi,
          {
            ...data
          },
          null
        );

        if (response.status === 200) {
          yield put(actionSaveCache.resetActivitiesCacheUser());
          yield put(actions.updateIsLoad('success'));
          if (action?.payload?.placeCreatePost) {
            if (data.project_id) {
              yield put(actionGrow.updateGrowStatusCount());
            } else if (data.shared_project_id) {
              yield put(actionGrow.updateGrowShareCount());
            } else if (data.shared_course_id) {
              yield put(actionCourse.updateCourseShareCount());
            }
          }
        }
      }
    }

    if (response && response.status === 200 && typePost !== 'comment') {
      yield put(actions.resetErrorPost());
      yield put(actions.updateIsLoad('success'));
      if (typeLive === 'livestream') {
        const dataLive = response.data;
        yield put(
          actionLive.updateInforLivestream({
            isLiveStream: true,
            post: dataLive
          })
        );
      }
      let dataRes = {
        post: response.data
      };

      //increment reblog_count of parent status
      if (
        place === 'shareAtFeed' ||
        place === 'shareAtNotFeed' ||
        place === 'shareAtGroupPageUser'
      ) {
        if (action.payload.increment_reblog_count)
          action.payload.increment_reblog_count();
      }

      if (
        place === 'shareAtFeed' ||
        place === 'shareAtNotFeed' ||
        place === 'shareAtGroupPageUser' ||
        place === 'shareAtFeedPage' ||
        place === 'post'
      ) {
        action.payload.setState && action.payload.setState(false);

        let messageNoti;
        const groupInfo = action.payload.data?.groupInfo;
        const checkAdminGroup: boolean =
          action.payload.data?.group_relationship?.admin ||
          action.payload.data?.group_relationship?.moderator;

        if (
          action.payload.data?.post_permission_setting !== 'ADMIN_ONLY' ||
          checkAdminGroup
        ) {
          if (
            place === 'post' &&
            typePost !== 'music' &&
            !dataRes?.post?.scheduled_at
          ) {
            messageNoti = 'Đăng bài viết thành công.';
          } else if (dataRes?.post?.scheduled_at) {
            messageNoti = 'Đã lên lịch cho bài viết của bạn.';
          } else if (typePost === 'music') {
            messageNoti = 'Chia sẻ bài hát thành công.';
          } else if (
            groupInfo?.groupName &&
            groupInfo?.permission === 'ADMIN_ONLY' &&
            !groupInfo?.groupRelationship?.admin &&
            !groupInfo?.groupRelationship?.moderator
          ) {
            messageNoti = `Bạn đã chia sẻ bài viết thành công lên ${groupInfo.groupName}, hệ thống đã gửi bài viết cho quản trị viên nhóm phê duyệt`;
          } else {
            messageNoti = 'Chia sẻ bài viết thành công';
          }
        }

        const notificationAction =
          (action.payload.data?.post_permission_setting !== 'ADMIN_ONLY' ||
            checkAdminGroup) &&
          messageNoti
            ? actions.notiSharePost({
                code: 200,
                msg: messageNoti
              })
            : null;
        if (notificationAction) {
          yield put(notificationAction);
        }
      }
      if (
        place === 'post' ||
        place === 'shareAtFeed' ||
        place === 'post_moment' ||
        (place === 'shareAtFeedPage' && data.page_owner_id)
      ) {
        yield put(actions.createPostSuccessAction(dataRes));
        yield put(actions.statusProcessing(dataRes.post.processing));
        if (dataRes?.post?.scheduled_at) {
          yield put(actionGroup.createSchedulePost(dataRes));
        }
      }

      if (dataRes?.post?.life_event && dataRes?.post?.account) {
        yield put(actionLife.getListLifeEventsRes(dataRes?.post?.account));
      }

      // if (data.group_id && data.shared_event_id) {
      //   yield put(actionEvent.shareEventSuccess(response.data.shared_event));
      // }
    } else if (typePost === 'comment') {
      yield put(actions.resetErrorPost());

      return response.data;
    }
  } catch (error: any) {
    yield put(
      actions.notiSharePost({
        code: error.response.status,
        msg: error.response?.data?.error
          ? error.response?.data?.error
          : place === 'post' && error.response.status !== 422
          ? 'Đăng bài viết không thành công.Vui lòng thử lại sau!'
          : null
      })
    );
    yield put(
      actions.errorPost({
        code: error.response?.status,
        message: error.response?.data?.error
      })
    );
    yield put(actions.resetFalsePostLoad());

    yield put(actions.notiPost(error.response?.data?.error));
  }
};

export const updatePostSocialSaga = function* (action) {
  yield put(actions.isUpdatingPost(false));
  let data = action.payload.data;
  let response;
  if (data?.media_ids?.length > 0) {
    const listIds = yield call(uploadMediaSaga, data.media_ids, data.post_type);
    if (listIds && listIds.length) {
      data['media_ids'] = listIds;
    }
    response = yield call(createUpdatePostApi, data, data.id);
  } else {
    response = yield call(createUpdatePostApi, data, data.id);
  }
  if (response && response.status === 200) {
    let dataRes = response.data;
    yield put(
      actions.notiSharePost({
        code: 200,
        msg: 'Chỉnh sửa bài viết thành công'
      })
    );
    yield put(actions.statusProcessing(dataRes.processing));
  } else {
    yield put(
      actions.notiSharePost({
        code: 500,
        msg: 'Chỉnh sửa bài viết không thành công'
      })
    );
  }
  yield put(actions.isUpdatingPost(true));

  if (data.pinned) {
    yield put(actions.updatePostSuccessAction(response.data, { pinned: true }));
  } else {
    yield put(actions.updatePostSuccessAction(response.data));
    if (data.post_type === 'scheduled_at') {
      yield put(actionGroup.updateSchedulePost(response.data));
    }
  }
};

export const deletePostRequested = function* (action) {
  try {
    let postId = action.payload.postId;
    let response;
    response = yield call(deletePostApi, postId);
    if (response?.status === 200) {
      yield put(actions.deletePostSuccess({ postId: postId }));
    }
  } catch (error) {}
};

export const getSchedulePostSagas = function* (action) {
  try {
    let { id, type } = action.payload;
    let response;
    if (type === 'page') {
      response = yield call(getScheduledPostPage, id);
    } else if (type === 'group') {
      response = yield call(getSchedulePostGroupApi, id);
    }
    if (response?.status === 200) {
      yield put(actions.getSchedulePostSuccess(response.data));
    }
  } catch (error) {}
};
export const getListPinPostSagas = function* ({ payload }) {
  try {
    const { id, type, path } = payload;
    let response;

    if (type === 'page' && id) {
      response = yield call(getPinnedPostPage, id, null);
    } else if (type === 'stream_group' && id) {
      response = yield call(getPinnedPostGroup, id, null);
    } else if (type === 'stream_profile' && id) {
      response = yield call(getPinnedPostUser, id, null);
    }

    if (response.status === 200 && path === window.location.href) {
      let data = response.data;
      yield put(actions.getListPinPostSuccess(data));
      if (type === 'stream_profile') {
        yield put(actionUser.savePinUser({ pinUser: data, userId: id }));
      }
    }
  } catch (error) {}
};

export const getListBookmarkCollectionSagas = function* () {
  try {
    let response = yield call(crudBookmarkCollectionApi, 'GET', null, null);
    if (response.status === 200) {
      yield put(actions.getListBookmarkCollectionSuccess(response.data));
    }
  } catch (error: any) {}
};

export function* watchGetPostSocialAsync() {
  yield takeEvery(typesSetting.GET_POST_REQUEST, getPostSocialSagas);
  yield takeEvery(typesSetting.CREATE_POST_REQUEST, createPostRequested);
  yield takeEvery(typesSetting.UPDATE_POST_REQUESTED, updatePostSocialSaga);
  yield takeEvery(typesSetting.DELETE_POST_REQUESTED, deletePostRequested);
  yield takeEvery(typesSetting.GET_SCHEDULED_POST_REQ, getSchedulePostSagas);
  yield takeEvery(typesSetting.GET_PIN_POST_REQ, getListPinPostSagas);
  yield takeEvery(
    typesSetting.GET_LIST_BOOKMARK_COLLECTION_REQ,
    getListBookmarkCollectionSagas
  );
}
