import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import * as React from 'react';
import {
  convertISO8086toLDate,
  convertISO8086toLTime
} from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));
const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : 'transparent',
    padding: '16px 8px 2px 12px',
    transition: theme.transitions.create(['border-color', 'background-color']),
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#4f4f4f'
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#4f4f4f',
      borderColor: theme.palette.primary.main
    },
    '& .MuiFilledInput-input': {
      padding: '8px 4px'
    }
  }
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open?: boolean | any;
  handleClose?: any;
  formik?: any;
  postSelected?: any;
  handleActionUpdate?: any;
  setSchedulePost?: React.Dispatch<React.SetStateAction<any>> | any;
  title?: String;
  typeTimeUpdate?: String;
  description?: String;
  buttonClose?: String;
  maxDay?: any;
  minDay?: any;
  maxTime?: any;
  minTime?: any;
  maxTimeCurrent?: any;
  labelButton?: String;
}

export default function CustomizedDialogs(props: Props) {
  const {
    open,
    handleClose,
    formik,
    postSelected,
    handleActionUpdate,
    setSchedulePost,
    title,
    typeTimeUpdate,
    description,
    buttonClose,
    maxDay,
    minDay,
    maxTime,
    minTime,
    maxTimeCurrent,
    labelButton
  } = props;
  const theme: any = useTheme();
  const [listTimeNotification, setListTimeNotification] = React.useState([]);
  const [valueDate, setValueDate] = React.useState(maxTimeCurrent);
  const [day, setDay] = React.useState<any>(new Date());
  const [time, setTime] = React.useState<any>(null);
  const [errorInput, setErrorInput] = React.useState<any>(false);

  const getListTime = (startWorkTime: any, endWorkTime: any) => {
    var startArrayTime = startWorkTime?.split(':');
    var endArrayTime = endWorkTime?.split(':');

    let timeMinuteStart: any =
      parseInt(startArrayTime[0]) * 60 + parseInt(startArrayTime[1]);
    let timeMinuteEnd: any =
      parseInt(endArrayTime[0]) * 60 + parseInt(endArrayTime[1]);

    let listTime: any = [];

    let startMinute = Math.ceil(timeMinuteStart / 5) * 5;

    if (timeMinuteStart % 5 !== 0) {
      startMinute = Math.ceil(timeMinuteStart / 5) * 5;
    }

    for (let i = startMinute; i <= timeMinuteEnd; i += 5) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;

      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      listTime.push(formattedTime);
    }

    setTime(listTime[0]);
    setListTimeNotification(listTime);
  };

  const getListTimeUpDate = (startWorkTime: any) => {
    var startArrayTime = startWorkTime?.split(':');

    let timeMinuteStart: any =
      parseInt(startArrayTime[0]) * 60 + parseInt(startArrayTime[1]);

    let listTime: any = [];

    for (var i = 0; i < timeMinuteStart / 10; i++) {
      listTime.push(timeMinuteStart - i * 10 - 1);
    }

    let listTimeCalendar = listTime?.map(
      (item: number) =>
        (Math.floor(item / 60) < 10
          ? '0' + Math.floor(item / 60)
          : Math.floor(item / 60).toString()) +
        ':' +
        (item % 60 < 10 ? '0' + (item % 60) : (item % 60).toString())
    );

    setListTimeNotification(listTimeCalendar);
  };

  const calculateNextHours = () => {
    const currentTime = new Date(); // Lấy thời gian hiện tại
    let currentHour = currentTime.getHours(); // Giờ hiện tại
    let currentMinute = currentTime.getMinutes(); // Phút hiện tại
    const nextHoursList: any = [];

    let startMinute = Math.ceil(currentMinute / 5) * 5;

    if (startMinute === 60) {
      startMinute = 0;
      currentHour += 1;
    }

    if (currentMinute % 5 === 0 && startMinute === 0) {
      startMinute += 5;
      if (startMinute === 60) {
        startMinute = 0;
        currentHour += 1;
      }
    }

    // Tạo các mốc thời gian tiếp theo với bước 5 phút
    for (let hour = currentHour; hour < 24; hour++) {
      for (let minute = startMinute; minute < 60; minute += 5) {
        const hours = String(hour).padStart(2, '0');
        const minutes = String(minute).padStart(2, '0');
        nextHoursList.push(`${hours}:${minutes}`);
      }
      startMinute = 0;
    }

    setListTimeNotification(nextHoursList);
  };

  const handleCloseDialog = type => {
    handleClose(type);
  };

  const handleAction = () => {
    let originalDate;
    let date;
    let month;
    let year;
    let sendDay;
    if (formik) {
      formik.setFieldValue('scheduled_at', day + ' ' + time);
      formik.submitForm();
    } else if (handleActionUpdate && postSelected) {
      originalDate = new Date(valueDate);

      date = originalDate.getDate();
      month = originalDate.getMonth() + 1;
      year = originalDate.getFullYear();

      sendDay = `${date}/${month}/${year}`;
      handleActionUpdate(
        'update',
        typeTimeUpdate === 'post_update'
          ? {
              id: postSelected?.id,
              backdated_time: sendDay + ' ' + time
            }
          : {
              id: postSelected?.id,
              scheduled_at: day + ' ' + time
            },
        null
      );
    }

    if (setSchedulePost) {
      let data_format = day;
      if (typeof day === 'string') {
        data_format = day.split('-').reverse().join('-');
      }
      originalDate = new Date(data_format);

      date = originalDate.getDate();
      month = originalDate.getMonth() + 1;
      year = originalDate.getFullYear();

      sendDay = `${date}/${month}/${year} ${time}`;
      setSchedulePost(sendDay);
    }

    if (description) {
      setValueDate(day + ' ' + time);
    } else {
      setDay(day + ' ' + time);
    }

    handleCloseDialog('submit');
  };

  const handleDatePickerChange = date => {
    if (isNaN(date)) {
      setErrorInput(true);
    } else {
      if (description) {
        setValueDate(date);
        if (new Date(date) > new Date(maxDay)) {
          setErrorInput(true);
        } else {
          setErrorInput(false);
        }
      } else {
        setDay(convertISO8086toLDate(date));
        if (
          new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
        ) {
          setErrorInput(true);
        } else {
          setErrorInput(false);
        }
      }
    }
  };

  // const handleRoundCurrentTime = () => {
  //   const event = new Date(); // Tạo đối tượng Date hiện tại
  //   const minutes = event.getMinutes(); // Lấy giá trị phút

  //   const roundedMinutes = Math.ceil(minutes / 10) * 10; // Làm tròn phút đến 10 phút gần nhất
  //   event.setMinutes(roundedMinutes); // Đặt giá trị phút mới

  //   const hours = event.getHours().toString().padStart(2, '0'); // Lấy giá trị giờ và chuyển thành chuỗi, đảm bảo có 2 chữ số
  //   const currentTimeString = `${hours}:${roundedMinutes
  //     .toString()
  //     .padStart(2, '0')}`; // Kết hợp giờ và phút thành chuỗi

  //   return currentTimeString;
  // };

  const handleRoundCurrentTime = () => {
    const event = new Date(); // Tạo đối tượng Date hiện tại
    let minutes = event.getMinutes(); // Lấy giá trị phút

    // Làm tròn lên 5 phút tiếp theo
    minutes = Math.ceil(minutes / 5) * 5;

    if (minutes === 60) {
      minutes = 0; // Nếu phút vượt quá 60 thì reset về 00
      event.setHours(event.getHours() + 1); // Cộng thêm 1 giờ
    }

    const hours = event.getHours().toString().padStart(2, '0'); // Lấy giá trị giờ
    const currentTimeString = `${hours}:${minutes.toString().padStart(2, '0')}`; // Kết hợp giờ và phút thành chuỗi

    return currentTimeString;
  };

  React.useEffect(() => {
    let data_format = day;
    if (typeof day === 'string') {
      data_format = day.split('-').reverse().join('-');
    }
    if (
      day &&
      convertISO8086toLDate(data_format) === convertISO8086toLDate(new Date())
    ) {
      let newTimeStart = handleRoundCurrentTime();
      getListTime(newTimeStart, '23:55');
    } else if (new Date(data_format) > new Date()) {
      getListTime('00:00', '23:55');
    } else if (description && new Date(valueDate) < new Date(maxDay)) {
      getListTime(
        minTime
          ? convertISO8086toLTime(minTime)
          : postSelected?.scheduled_at
          ? convertISO8086toLTime(postSelected.scheduled_at)
          : '00:00',
        maxTime &&
          maxDay &&
          convertISO8086toLDate(maxDay) === convertISO8086toLDate(day)
          ? convertISO8086toLTime(maxTime)
          : '23:55'
      );
    } else if (maxTime) {
      getListTimeUpDate(convertISO8086toLTime(maxTime));
    }
  }, [day, valueDate]);

  React.useEffect(() => {
    if (time) {
      calculateNextHours();
    }
  }, [time]);

  React.useEffect(() => {
    if (
      typeTimeUpdate === 'post_update' &&
      postSelected &&
      Object.keys(postSelected).length
    ) {
      setDay(convertISO8086toLDate(postSelected?.backdated_time));
      setTime(convertISO8086toLTime(postSelected?.backdated_time));
    } else if (postSelected && Object.keys(postSelected).length) {
      setDay(convertISO8086toLDate(postSelected.scheduled_at));
      setTime(convertISO8086toLTime(postSelected.scheduled_at));
    }
  }, [JSON.stringify(postSelected)]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleCloseDialog('close')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            m: 0,
            p: 1
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => handleCloseDialog('close')}
            sx={{
              position: 'absolute',
              left: buttonClose === 'x' ? undefined : 8,
              right: buttonClose !== 'x' ? undefined : 8,
              //   top: 8,
              height: 38,
              width: 38,
              backgroundColor:
                theme.palette.mode === 'light' ? '#E4E6EB' : '#4e4e4e'
            }}
          >
            <i
              className={
                buttonClose === 'x' ? 'fa-light fa-xmark' : 'far fa-angle-left'
              }
            ></i>
          </IconButton>
          <Typography variant="h6">
            {title
              ? title
              : postSelected && Object.keys(postSelected).length
              ? 'Chỉnh sửa lịch đăng bài'
              : 'Lên lịch đăng bài'}
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ overflowX: 'hidden' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {description
                ? description
                : 'Chọn ngày và giờ trong tương lai mà bạn muốn đăng bài viết của mình.'}
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={minDay}
                  inputFormat={'dd-MM-yyyy'}
                  label="Ngày"
                  value={
                    //Thư viện sử dụng form YYYY-MM-DD, nhưng dữ liệu của day chỉ có trường hợp DD-MM-YYYY
                    description
                      ? valueDate
                      : new Date(
                          typeof day === 'string'
                            ? `${day?.split('-')[2]?.split(' ')[0]}-${
                                day?.split('-')[1]
                              }-${day?.split('-')[0]}`
                            : day
                        )
                  }
                  shouldDisableDate={
                    description
                      ? date => new Date(maxDay) < new Date(date)
                      : undefined
                  }
                  onChange={handleDatePickerChange}
                  renderInput={params => (
                    <RedditTextField
                      {...params}
                      label="Ngày"
                      error={errorInput}
                      sx={{
                        width: '100%',
                        fontWeight: '700',
                        borderRadius: '15px',
                        marginRight: '2px'
                      }}
                      helperText={
                        errorInput ? 'Vui lòng điền đúng ngày hợp lệ!' : null
                      }
                    />
                  )}
                  InputAdornmentProps={{
                    position: 'start',
                    variant: 'standard'
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="size-small-outlined"
                fullWidth
                options={listTimeNotification}
                getOptionLabel={option => `${option}`}
                value={time}
                filterSelectedOptions
                onChange={(evt, value) => {
                  if (value) {
                    setTime(value);
                  } else {
                    //reset time when click close
                    setTime(null);
                    getListTime(handleRoundCurrentTime(), '23:55');
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Thời gian"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <ButtonInherit
            disabled={!day || !time || errorInput ? true : false}
            action={handleAction}
            color={
              errorInput
                ? 'red'
                : theme.palette.mode === 'light'
                ? '#E4E6EB'
                : '#4e4e4e'
            }
            style={{
              color: theme.palette.mode === 'light' ? '#000' : '#fff'
            }}
            label={
              labelButton
                ? labelButton
                : postSelected
                ? 'Cập nhật lịch'
                : 'Lên lịch'
            }
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
