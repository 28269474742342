import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface CustomizedDialogsProps {
  title?: any;
  text?: any;
  open?: any;
  handleClose?: any;
  action?: any;
  icon?: any;
}

export default function DialogBlockRegister(props: CustomizedDialogsProps) {
  const { icon, title, text, open, handleClose } = props;

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '900px'
        }
      }}
      BackdropProps={modalBackdrop}
    >
      {Array.isArray(icon) && icon[0] && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '25px'
          }}
        >
          <img
            src={icon[0]}
            style={{
              height: '50px',
              width: '50px',
              objectFit: 'contain'
            }}
            alt="EmsoImage"
          />
        </div>
      )}
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 18,
            marginTop: '10px'
          }}
        >
          {title}
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent id="scroll-dialog-content">
        <Typography
          style={{
            fontSize: 15,
            wordBreak: 'break-word',
            textAlign: 'center'
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </DialogContent>
      {Array.isArray(icon) && icon[1] && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '25px'
          }}
        >
          <img
            src={icon[1]}
            style={{
              height: '130px',
              width: '210px',
              objectFit: 'contain'
            }}
            alt="logoImage"
          />
        </div>
      )}

      {Array.isArray(icon) && icon[2] && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '25px'
          }}
        >
          <img
            src={icon[2]}
            style={{
              height: '350px',
              width: '350px',
              objectFit: 'contain'
            }}
            alt="QR_Image"
          />
        </div>
      )}
    </BootstrapDialog>
  );
}
