import CloseIcon from '@mui/icons-material/Close';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { pageIdentityVerification } from 'src/apis/socialPages.api';
import {
  getListVerificationUser,
  verificationsUser
} from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { phoneRegExp } from 'src/constants/regex';
import { modalBackdrop, WebkitScrollbarRGB } from 'src/constants/styles';
import { updateInfoMe } from 'src/store/action/userAction';
import * as Yup from 'yup';
import SocialFieldUpdate from '../SocialFieldUpdate/SocialFieldUpdate';
import DialogEditMedia from './DialogCUPost/CUMenuUi/DialogEditMedia';
import { RegText } from 'src/common/string';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    ...WebkitScrollbarRGB({}),
    padding: '8px 24px',
    border: '1px 0 1px 0 solid rgba(0, 0, 0, 0.12)'
  },
  '& .MuiPaper-root': {
    width: 600,
    borderRadius: '8px'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogVerificationUser(props) {
  const {
    open,
    setOpen,
    setNoti,
    setOpenSnackbar,
    itemSelected,
    setStatusVerificationLocal,
    getDetailPage
  } = props;

  const dispatch = useDispatch();

  const [verify, setVerify] = React.useState<any>(false);
  const [files, setFiles] = React.useState<any>([]);
  const [openEditMedia, setOpenEditMedia] = React.useState(false);
  const [loadingVerification, setLoadingVerification] = React.useState(false);
  const [infoVerification, setInfoVerification] = React.useState<any>('');
  const [checkSendVerification, setCheckSendVerification] =
    React.useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Không được để trống')
      .min(5, 'Tối thiếu là 5 ký tự')
      .max(30, 'Tối đa 30 ký tự')
      .matches(RegText, 'Tên không được chứa ký tự đặc biệt')
      .nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'Số điện thoại không hợp lệ.Vui lòng nhập lại!!')
      .typeError('Số điện thoại không hợp lệ.Vui lòng nhập lại!!')
      .min(10, 'Số điện thoại phải bằng 10 số')
      .max(10, 'Số điện thoại phải bằng 10 số')
      .required('Không được để trống'),
    identity_verification_video: Yup.mixed()
      .required('Video là bắt buộc!')
      .test('fileSize', 'Video không được quá 30s!', value => {
        return value && value?.file?.file?.meta?.original?.duration <= 30;
      })
      .nullable()
  });
  const validationSchemaPage = Yup.object().shape({
    tax_code: Yup.number()
      .typeError('Vui lòng chỉ nhập số')
      .integer('Số phải là số nguyên')
      .min(0, 'Số phải lớn hơn hoặc bằng 0')
  });

  const formik = useFormik({
    validationSchema: !itemSelected?.page_relationship //Check if this verification is user verification?
      ? validationSchema
      : validationSchemaPage,
    initialValues: {
      name: '',
      phone_number: '',
      identity_card_back: null as any,
      identity_card_front: null as any,
      identity_verification_video: null as any,
      tax_code: '' as any,
      company: '' as any,
      business_registration_id: null as any
    },
    onSubmit: () => {
      try {
        if (
          infoVerification &&
          (!itemSelected?.page_relationship ||
            itemSelected?.page_type === 'personal')
        ) {
          handleVerification('update');
        } else {
          handleVerification('create');
        }
      } catch (err) {
        console.log(err);
      }
    }
  });

  React.useEffect(() => {
    getVerificationUser();
  }, []);

  React.useEffect(() => {
    if (
      itemSelected?.page_relationship?.role === 'admin' ||
      itemSelected?.page_relationship?.role === 'owner'
    ) {
      if (
        formik.values.business_registration_id ||
        itemSelected?.page_type === 'personal'
      ) {
        setCheckSendVerification(true);
      } else {
        setCheckSendVerification(false);
      }
    } else {
      if (
        formik.values.identity_card_back &&
        formik.values.identity_card_front &&
        formik.values.identity_verification_video &&
        Object.keys(formik.errors).length === 0
      ) {
        setCheckSendVerification(true);
      } else {
        setCheckSendVerification(false);
      }
    }
  }, [
    JSON.stringify(formik.values),
    JSON.stringify(formik.errors),
    JSON.stringify(itemSelected)
  ]);

  React.useEffect(() => {
    let tempData: any;
    if (
      Object.keys(infoVerification).length > 0 &&
      infoVerification.id !== '' &&
      (!itemSelected?.page_relationship ||
        itemSelected?.page_type === 'personal')
    ) {
      tempData = {
        name: infoVerification?.name ? infoVerification?.name : null,
        phone_number: infoVerification?.phone_number
          ? infoVerification?.phone_number
          : null,
        identity_card_back: infoVerification?.identity_card_back
          ? infoVerification?.identity_card_back
          : null,
        identity_card_front: infoVerification?.identity_card_front
          ? infoVerification?.identity_card_front
          : null,
        identity_verification_video:
          infoVerification?.identity_verification_video
            ? infoVerification?.identity_verification_video
            : null
      };
    } else {
      tempData = {
        tax_code: null,
        company: null,
        business_registration_id: null
      };
    }
    formik.setValues(tempData);
  }, [JSON.stringify(infoVerification)]);

  const mediaVerification: any = [
    {
      field_type: 'textField',
      name: 'name',
      placeholder: 'Vui lòng nhập...',
      label: 'Họ và tên ',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      defaultValue: infoVerification?.name,
      disabled: infoVerification?.status === 'approved'
    },
    {
      field_type: 'textField',
      name: 'phone_number',
      placeholder: 'Vui lòng nhập...',
      label: 'Số điện thoại',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      defaultValue: infoVerification?.phone_number,
      disabled: infoVerification?.status === 'approved'
    },
    {
      field_type: 'upload_media',
      name: 'identity_card_front',
      label: 'Ảnh CCCD (Mặt trước)',
      type: 'file_media',
      height: 200,
      descriptionLabel: 'Bắt buộc*',
      default_media: infoVerification?.identity_card_front
    },
    {
      field_type: 'upload_media',
      name: 'identity_card_back',
      label: 'Ảnh CCCD (Mặt sau)',
      type: 'file_media',
      height: 200,
      descriptionLabel: 'Bắt buộc*',
      default_media: infoVerification?.identity_card_back
    },
    {
      field_type: 'upload_media',
      name: 'identity_verification_video',
      label:
        'Video xác định danh tính (không quá 30 giây), Vui lòng quay trực tiếp gương mặt, đủ ánh sáng. Đọc rõ tên đúng với căn cước công dân',
      type: 'file',
      type_video: 'file_video_verification',
      height: 200,
      descriptionLabel: 'Bắt buộc*',
      default_media: infoVerification?.identity_verification_video
    }
  ];

  const listFieldVerificationPage: any = [
    {
      field_type: 'upload_media',
      name: 'business_registration_id',
      label: 'Giấy phép ĐKKD hoặc CCCD, hộ chiếu',
      type: 'file_media',
      height: 200,
      descriptionLabel: 'Bắt buộc*'
    },
    {
      field_type: 'textField',
      name: 'tax_code',
      placeholder: 'Mã số thuế',
      label: 'Mã số thuế',
      onChange: true,
      maxRows: 3
    },
    {
      field_type: 'textField',
      name: 'company',
      placeholder: 'Tên chủ doanh nghiệp',
      label: 'Tên chủ doanh nghiệp',
      onChange: true,
      maxRows: 3
    }
  ];

  const getVerificationUser = async () => {
    try {
      let res = await getListVerificationUser();
      if (res.status === 200) {
        setInfoVerification(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVerification = async type => {
    try {
      let response;
      if (
        !itemSelected?.page_relationship ||
        itemSelected?.page_type === 'personal'
      ) {
        response = await verificationsUser(
          {
            name: formik.values.name,
            phone_number: formik.values.phone_number,
            identity_card_back: formik.values.identity_card_back
              ? {
                  file: formik.values.identity_card_back?.file,
                  id:
                    formik.values.identity_card_back?.id ??
                    formik.values.identity_card_back?.file.id,
                  show_url: formik.values.identity_card_back.show_url
                }
              : null,
            identity_card_front: formik.values.identity_card_front
              ? {
                  file: formik.values.identity_card_front?.file,
                  id:
                    formik.values.identity_card_front?.id ??
                    formik.values.identity_card_front?.file.id,
                  show_url: formik.values.identity_card_front.show_url
                }
              : null,
            identity_verification_video: formik.values
              .identity_verification_video
              ? {
                  file: formik.values.identity_verification_video?.file,
                  id:
                    formik.values.identity_verification_video?.id ??
                    formik.values.identity_verification_video?.file.id,
                  show_url: formik.values.identity_verification_video.show_url
                }
              : null
          },
          type
        );

        dispatch(updateInfoMe({ status_identity_verification: response.data }));
      } else {
        response = await pageIdentityVerification(
          {
            tax_code: formik.values.tax_code,
            company: formik.values.company,
            business_registration_id: formik.values.business_registration_id
              ? {
                  file: formik.values.business_registration_id?.file,
                  id:
                    formik.values.business_registration_id?.id ??
                    formik.values.business_registration_id?.file.id,
                  show_url: formik.values.business_registration_id.show_url
                }
              : null,
            page_id: itemSelected?.id
          },
          itemSelected?.status_identity_verification ? 'update' : 'create'
        );
      }
      if (response.status === 200) {
        if (
          ['admin', 'owner'].includes(itemSelected?.page_relationship?.role) &&
          itemSelected?.page_type !== 'personal'
        ) {
          getDetailPage && getDetailPage();
        } else {
          getVerificationUser();

          if (setStatusVerificationLocal) setStatusVerificationLocal(true);
        }
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message:
            'Bạn đã gửi xác minh  thành công, chúng tôi sẽ xem xét và sớm gửi thông báo đến bạn!'
        });
        setOpen(false);
        setLoadingVerification(false);
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setOpen(false);
      setLoadingVerification(false);
      setNoti({
        code: 400,
        message: err.response.status
          ? err.response.data.error
          : 'Gửi xác minh  thất bại.Vui lòng thử lại sau!'
      });
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      BackdropProps={modalBackdrop}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          Xác minh danh tính
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          maxHeight: '100vh'
        }}
      >
        {itemSelected?.status_identity_verification?.status === 'rejected' && (
          <Chip
            sx={{
              borderRadius: '4px',
              justifyContent: 'start',
              fontSize: '16px',
              margin: '10px 0'
            }}
            color="error"
            variant="outlined"
            label={`Lý do bị từ chối: ${itemSelected?.status_identity_verification?.note}`}
          />
        )}

        <SocialFieldUpdate
          setVerify={setVerify}
          verify={verify}
          formik={formik}
          fieldUpdate={
            itemSelected?.page_relationship?.role === 'admin' &&
            itemSelected?.page_type !== 'personal'
              ? listFieldVerificationPage
              : mediaVerification
          }
          spacing={0}
        />

        <DialogEditMedia
          files={files}
          setFiles={setFiles}
          open={openEditMedia}
          handleClose={() => setOpenEditMedia(false)}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'end', p: 2 }}>
        <ButtonInherit
          label={
            (infoVerification && !itemSelected?.page_relationship) ||
            (infoVerification && itemSelected?.page_type === 'personal')
              ? 'Chỉnh sửa phê duyệt'
              : 'Gửi phê duyệt'
          }
          style={{ marginRight: '0px' }}
          styleText={{
            whiteSpace: 'nowrap',
            padding: '0px 16px'
          }}
          action={() => {
            formik.handleSubmit();
            setLoadingVerification(true);
          }}
          loading={loadingVerification}
          disabled={!checkSendVerification}
        />
      </DialogActions>
    </BootstrapDialog>
  );
}
